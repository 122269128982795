import React, { useContext, useEffect, useState } from 'react'
import { useAdminAutoTradeSetup } from '_hooks/useAdminAutoTradeSetup'
import { useAdminStockSymbols } from '_hooks/useAdminStockSymbols';
import { UrlHelper } from '_utils/UrlHelper';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { AutoTradeSetupStatusEnum, IAutoTradeSetup, AutoTradeSetupStatusEnumHelper, IBKROrderType, OptionType, NewYorkTz, StockHelper } from 'predictagram-lib';
import { useNavigate } from 'react-router-dom';
import { adminAutoTradeSetupService } from 'services/AdminApiService';
import { TradeSetupStatusFilter } from './filters/TradeSetupStatusFilter';
import { StockSymbolFilter } from './filters/StockSymbolFilter';
import { IAdminSignalAlertApi, useAdminSignalAlerts } from '_hooks/useAdminSignalAlerts';
import { cacheStats } from '../analysis/alerts/List';
import { Link } from 'react-router-dom';
import { Square, Check2Square, Clock } from 'react-bootstrap-icons';
import { IbkrOrderTypeDropDown } from './filters/IbkrOrderTypeDropDown';
import { MessengerContext } from 'components/common/messenger';
import { TradeModel } from 'models/trade.model';
import { onClickHistory } from './TradesListPage';
import { StartEndDateFilter } from 'components/common/StartEndDateFilter';
import { AlertSummaryModel, FilterOptions } from '../analysis/alert-summary-report/alert-summary.model';
import {Trade} from "../../user/dashboard/Trade";



export const SignalPerformance: React.FunctionComponent<{adminSignalAlerts: IAdminSignalAlertApi, signalId: number|null, simple?: boolean}> = ({adminSignalAlerts, signalId, simple=false}) => {
  const { api: signalApi, getById: getSignalById } = adminSignalAlerts;

  return <>
    {signalApi.apiState.isLoaded ? 
      (()=>{
        // const signalId = TradeModel.getSignalIdFromTradeSetupName(signalAlertId);
        if (signalId) {
          const signal = getSignalById(signalId);
          if (signal) {
            return <div className="d-flex flex-column gap-1">
              {cacheStats(signal, simple)}
              {!!!simple && <Link to={UrlHelper.getAdminSignalAlertEdit(signalId)} target="_blank">View Signal</Link>}
            </div>;
          }
        }
        return 'N/A'
      })()
      :
      <>...</>
    }    
  </>;
}

export const TradeSetupListPage:React.FunctionComponent = () => {

  const { api } = useAdminAutoTradeSetup();
  const {api: symbolApi, getById: getBySymbolId} = useAdminStockSymbols();
  //const {api: signalApi, getById: getSignalById} = useAdminSignalAlerts();
  const adminSignalAlerts = useAdminSignalAlerts();
  const msgrContext = useContext(MessengerContext);

  const navigate = useNavigate();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [tradeSetupStatusFilter, setTradeSetupStatusFilter] = useState<AutoTradeSetupStatusEnum | undefined>(undefined);
  const [stockSymbolFilter, setStockSymbolFilter] = useState<number | undefined>(undefined);
  const [filteredData, setFilteredData] = useState<IAutoTradeSetup[]>([]);



  const stockDay = StockHelper.findTradingDay(new Date(), -1, false);
  const todayStart = stockDay.openAt();
  const todayEnd = stockDay.closeAt();
  const [startDate, setStartDate] = useState<Date>(new Date(todayStart * 1000));
  const [endDate, setEndDate] = useState<Date>(new Date(todayEnd * 1000));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  useEffect(()=>{
    if (api.apiState.isLoaded) {
      setFilteredData([
        ...api.data
        .filter(setup => tradeSetupStatusFilter ? setup.statusId.toString() === tradeSetupStatusFilter.toString() : true)
        .filter(setup => stockSymbolFilter ? stockSymbolFilter.toString() === setup.analysisSetup.stockSymbolId.toString() : true)
      ])
    } else {
      setFilteredData([]);
    }
  },[tradeSetupStatusFilter, stockSymbolFilter, api.apiState.isLoaded, api.data])


  useEffect(()=>{
    setSelectedIds([]);
  },[filteredData])

  if (api.apiState.isError) {
    return <ErrorMessage className='bg-white'>{api.error}</ErrorMessage>
  }

  if (api.apiState.isLoading) {
    return <Spinner />
  }

  const handleEdit = (id: number) => {
    window.open(UrlHelper.getAdminAutoTradeSetupEdit(id), '_blank');
  }

  const handleShowTrade = (setupId?: number) => {
    navigate(UrlHelper.getAdminAutoTrades(setupId))
  }

  const handleSelected = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(i=>i !== id));
      return;
    }
    setSelectedIds([
      ...selectedIds,
      id
    ])
  }

  const handleSelect = async (statusId: AutoTradeSetupStatusEnum) => {
    try {
      const updatePromises = selectedIds.map((id) => adminAutoTradeSetupService.update({ statusId } as IAutoTradeSetup, id));
      await Promise.all(updatePromises);
      api.reload(); // refreshes the dataset
      setSelectedIds([]);
    } catch (error: any) {
        console.error(error);            
    }
  }

  const handleSelectAll = () => {
    if (selectedIds.length === filteredData.length) {
      setSelectedIds([]);
    }
    else {
      setSelectedIds([
        ...filteredData.map(i=>i.id)
      ]);
    }
  }

  const updateOrderType = async (v: IBKROrderType, setup: IAutoTradeSetup) => {
    const payload: any  = {
      ...setup
    }
    delete payload['tradeSecurityTypeId'];
    delete payload['id'];
    delete payload['tradesOpen'];
    delete payload['createdAt'];
    payload.tradeTemplate.ibkr.orderType = v;
    try {
      await adminAutoTradeSetupService.update(payload, setup.id)
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: 'Failed to update.' });
    }
  }

  const onClickRunAlertCombined = async (isCompact=false) => {

    if (selectedIds.length === 0) {
      alert('No signal ids selected');
      return;
    }

    const signalAlertIds = filteredData.map( item => {
        return (selectedIds.includes(item.id) && item.signalAlertId) || 0
    }).filter(d => d !== 0);

    if (!signalAlertIds || signalAlertIds.length === 0) {
      alert('No signal ids found.');
      return;
    }

    const searchOptions: FilterOptions = {
      endTime: endDate.getTime() / 1000,
      startTime: startDate.getTime() / 1000,
      signalAlertIds: [...signalAlertIds]
    }

    try {
      await AlertSummaryModel.generateReport(searchOptions, setStatus, setIsLoading, isCompact);
    } catch (e: any) {
      setIsLoading(false);
      alert('Error retrieving report. Possibly an invalid Signal Alert ID');
      console.error(e);
      setStatus(e.toString());
    }
  }

  if (api.apiState.isLoaded && symbolApi.apiState.isLoaded) {
    return <div>
      <div className="page-title mb-3">Trade Setups</div>

      <div className="d-flex gap-3 my-3">
        <button className="btn btn-primary" onClick={()=>handleSelect(AutoTradeSetupStatusEnum.PAUSED)}>Pause Selected Setups</button>
        <button className="btn btn-primary" onClick={()=>handleSelect(AutoTradeSetupStatusEnum.PAUSED_ENTRY)}>Pause Selected Setups Entry</button>
        <button className="btn btn-primary" onClick={()=>handleSelect(AutoTradeSetupStatusEnum.PAUSED_EXIT)}>Pause Selected Setups Exit</button>
        <button className="btn btn-primary" onClick={()=>handleSelect(AutoTradeSetupStatusEnum.ACTIVE)}>Activate Selected Setups</button>
        <TradeSetupStatusFilter setValue={setTradeSetupStatusFilter} value={tradeSetupStatusFilter} />
        <StockSymbolFilter value={stockSymbolFilter} setValue={setStockSymbolFilter}  />

        <div className="d-flex gap-2 border p-2">
          <StartEndDateFilter endDate={endDate} onEndDateChange={setEndDate} startDate={startDate} onStartDateChange={setStartDate} />
          <button className="btn btn-primary" onClick={()=>onClickRunAlertCombined(false)}>Run Alert Combined Report</button>
          <button className="btn btn-primary" onClick={()=>onClickRunAlertCombined(true)}>Run Alert Combined Rep. Compact</button>
          {isLoading && <Spinner isSmall={true} minHeight={15} />}
        </div>

      </div>
      <div className="fixed-table-head">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th><div role="button" onClick={handleSelectAll}>{selectedIds.length > 0 && selectedIds.length === filteredData.length ? <Check2Square /> : <Square />}</div></th>
            <th>ID</th>
            <th>Name</th>
            {/* <th>Emails for Alerts</th> */}
            <th>Symbol</th>
            <th>Signal Performance</th>
            <th>Effective Hours</th>
            <th className="text-end">Max Active Trades</th>
            <th className="text-end">Quantity per Trade</th>
            <th className="text-end">Trades Open</th>
            {/*<th>Side</th>*/}
            <th>Option Type</th>
            <th>Order Type</th>
            <th>Status</th>
            <th>Edit</th>
            <th>Show Trades</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.sort((a, b)=>b.id - a.id).map((item, index) => <tr key={`item-${item.id}`}>
            <td>
            <div role="button" className="text-center"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                handleSelected(item.id);
              }}
            >
              {selectedIds.includes(item.id) ? <Check2Square /> : <Square />}
            </div>
            </td>
            <td>{item.id}</td>
            <td>
              <div className="d-flex gap-1 flex-column">
              <div className="d-flex gap-1">
                {TradeModel.getTradeSetupFullTitle(item.name, item.signalAlertId)}
                {item.signalAlertId && <div role="button" className="text-primary" onClick={()=>onClickHistory(item.signalAlertId)} title="Cumulative Chart History"><Clock /></div>}
              </div>
              <div className="text-12">Created On: {new Date(item.createdAt * 1000).toLocaleString()}</div>
              </div>
            </td>
            <td>{getBySymbolId(item.analysisSetup.stockSymbolId)?.name}</td>
            <td className="text-nowrap"><SignalPerformance adminSignalAlerts={adminSignalAlerts} signalId={item.signalAlertId}/></td>
            <td>{item.analysisSetup.startHourEst} - {item.analysisSetup.endHourEst}</td>
            <td className="text-end">{item.maxActiveTrades}</td>
            <td className="text-end">{item.quantityPerTrade}</td>
            <td className="text-end">{item.tradesOpen}</td>
            {/*<td>{item.tradeTemplate.ibkr?.side}</td>*/}
            <td><div className={`fw-bold text-${item.tradeTemplate.optionType === OptionType.CALL ? 'green' : 'red'}`}>{item.tradeTemplate.optionType}</div></td>
            <td><IbkrOrderTypeDropDown value={item.tradeTemplate.ibkr?.orderType} setValue={(ibkrOrderType) => updateOrderType(ibkrOrderType, item )} /></td>
            <td>{AutoTradeSetupStatusEnumHelper.names.get(item.statusId)}</td>
            <td><button type="button" className="btn btn-primary" onClick={()=>handleEdit(item.id)}>Edit</button></td>
            {/* <td><button type="button" className="btn btn-primary">Delete</button></td> */}
            <td><button type="button" className={`btn btn-primary`} onClick={()=>handleShowTrade(item.id)}>Show Trades</button></td>
          </tr>)}
          <tr>

          </tr>
        </tbody>
      </table>
      </div>
      {/* <pre>{JSON.stringify(api.data, null, 2)}</pre> */}

    </div>
  }
  return <></>
}

