import { ITradeLivePrice, TradeModel } from "models/trade.model";
import { AutoTradeStatusEnum, IBKRSide, AutoTradePositionTypeEnum, IAutoTrade } from "predictagram-lib";
import { useCallback, useEffect, useState } from "react";
import { IDateRangeFilter, OpenedFilter } from "../filters/OpenedFilter";
import { PresetDateRange, PresetDateRangeFilter } from "../filters/PresetDateRangeFilter";
import { PositionTypeFilter } from "../filters/PositionTypeFilter";
import { StatusFilter } from "../filters/StatusFilter";
import { useExcelDownload } from "_hooks/useExcelDownload";
import { useAdminAutoTrades } from "_hooks/useAdminAutoTrades";
import { IAutoTradeSearchFilter, adminApiServiceCommon } from "services/AdminApiService";
import { ArrowClockwise } from "react-bootstrap-icons";
import { TradesSummary, calcUnrealizedProfitLossOptionFormatted, formatUSCurrency } from "../Trades";
import { useAdminStockSymbols } from "_hooks/useAdminStockSymbols";
import { StockSymbolFilter2 } from "../filters/StockSymbolFilter2";
import { SymbolsWatchList } from "../SymbolsWatchList";
import { Spinner } from "components/common/Spinner";


export function useTrades(setupId: string | null) {

  const [statusFilter, setStatusFilter] = useState<AutoTradeStatusEnum | undefined>(undefined);
  const [sideFilter, setSideFilter] = useState<IBKRSide | undefined>(undefined);
  const [positionTypeFilter, setPositionTypeFilter] = useState<AutoTradePositionTypeEnum | undefined>(undefined);
  const [presetDateRangeFilter, setPresetDateRangeFilter] = useState<PresetDateRange | undefined>(setupId ? undefined : PresetDateRange.TODAY);
  const [openedFilter, setOpenedFilter] = useState<IDateRangeFilter | undefined>(TradeModel.getOpenedFilterFromPreset(presetDateRangeFilter));
  const [setupFilter, setSetupFilter] = useState<number | undefined>(undefined);
  const [securityFilter, setSecurityFilter] = useState<string | undefined>(undefined);
  const [statusReasonFilter, setStatusReasonFilter] = useState<number | undefined>(undefined);
  const [stockSymbolFilter, setStockSymbolFilter] = useState<number | undefined>(undefined);
  const [excludeStockSymbol, setExcludeStockSymbol] = useState<boolean>(false);

  const [livePrices, setLivePrices] = useState<ITradeLivePrice[]>([]);

  const [showSymbols, setShowSymbols] = useState<boolean>(false);
  const [symbols, setSymbols] = useState<number[]>([]);

  const searchFilter: IAutoTradeSearchFilter = {
    startTime: openedFilter?.startTime,
    endTime: openedFilter?.endTime,
  }

  const api = useAdminAutoTrades(searchFilter);
  const symbolApi = useAdminStockSymbols().api;

  const [data, setData] = useState<IAutoTrade[]>([]);
  const [filteredData, setFilteredData] = useState<IAutoTrade[]>([]);

  const [symbolNames, setSymbolNames] = useState<Map<number, string>>(new Map<number, string>());

  const excelDownload = useExcelDownload<any>(TradeModel.exportMap, 'trades');

  const loadPrices = useCallback(async () => {
    if (!api.apiState.isLoaded) {
      return;
    }
    if (api.data) {
      setData(api.data);
    }
  }, [api.data, api.apiState.isLoaded]);

  useEffect(() => {
    loadPrices();
  }, [loadPrices]);


  useEffect(()=>{
    if (symbolApi.apiState.isLoaded) {
      const names = new Map<number, string>();
      symbolApi.data.forEach(d=>{
        names.set(d.id, d.name);
      })
      setSymbolNames(names);
    }
  }, [symbolApi.apiState.isLoaded, symbolApi.data])

  const Loading = () => {
    if (api.apiState.isLoading) {
      return <Spinner minHeight={10} />
    } else {
      return <></>
    }
  }


  const updateFilteredList = useCallback(async () => {

    const filtered = api.data.filter(trade => {
      return (!setupId || trade.setupId === parseInt(setupId)) &&
             //  (!sideFilter || trade.ibkrOpenOrder?.orderSide.toString() === sideFilter.toString()) &&
             (!positionTypeFilter || trade.positionTypeId.toString() === positionTypeFilter.toString()) &&
             (!setupFilter || trade.setupId === setupFilter) &&
             (!securityFilter || (trade.ibkr.openOrders.length > 0 && TradeModel.getSecurityOptionName(trade.ibkr.openOrders[0])) === securityFilter) &&
             (!stockSymbolFilter || (excludeStockSymbol ? stockSymbolFilter.toString() !== trade.stockSymbolId.toString() : stockSymbolFilter.toString() === trade.stockSymbolId.toString())) &&
             (!statusReasonFilter || trade.statusReasonId === statusReasonFilter);
    });

    setFilteredData(filtered);
    setSymbols(filtered.map((v) => v.stockSymbolId).filter((v: number, i: number, array: number[]) => array.indexOf(v) === i));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.data, securityFilter, setupFilter, statusReasonFilter]) //removed the following dependencies to avoid auto submit: positionTypeFilter, setupId, sideFilter, stockSymbolFilter

  // called whenever api state changes 
  useEffect(() => {
    if (api.apiState.isLoaded) {
      updateFilteredList();
    }
  }, [api.apiState.isLoaded, updateFilteredList])

  // loads the live prices into the filtered data set
  useEffect(() => {
    if (filteredData.length === 0) return;

    const _load = async () => {
      const livePrices: ITradeLivePrice[] = [];

      for (const d of filteredData) {
        // show prices only for a active and successful trades
        if (d.statusId === AutoTradeStatusEnum.ACTIVE  ) {
          try {
            const livePrice = await adminApiServiceCommon.getTradeLivePrices(d.id);
            livePrices.push({ id: d.id, ...livePrice })
            //console.debug(d.id, (d as any).livePrices);
          } catch (e) {
            console.error(e)
          }
        }
      }
      setLivePrices(livePrices);
    }
    _load();
  }, [filteredData])


  const updateList = useCallback(() => {
    const searchFilter: IAutoTradeSearchFilter = {
      startTime: openedFilter?.startTime,
      endTime: openedFilter?.endTime,
      autoTradeStatusIds: statusFilter ? [statusFilter] : undefined,
    }
    api.setFilter(searchFilter);
  }, [api, openedFilter?.startTime, openedFilter?.endTime, statusFilter])

  // called to udpate the start and end date selection
  useEffect(() => {
    const openedFilter = TradeModel.getOpenedFilterFromPreset(presetDateRangeFilter);
    setOpenedFilter(openedFilter);
  }, [presetDateRangeFilter])


  const renderLivePrices = (trade: IAutoTrade) => {
    const p = livePrices.find((t) => t.id === trade.id);
    if (!p) return <><td></td><td></td><td></td></>

    return (
      <>
        <td align="right">{formatUSCurrency(p.bidPrice)}</td>
        <td align="right">{formatUSCurrency(p.askPrice)}</td>
        <td align="right">{formatUSCurrency(p.lastPrice)}
          {[AutoTradeStatusEnum.ACTIVE, AutoTradeStatusEnum.CLOSED].includes(trade.statusId) && p.lastPrice && <div className="text-10 my-1">
            {calcUnrealizedProfitLossOptionFormatted(trade, p.lastPrice, true)}
          </div>
          }
        </td>
      </>
    );
  }

  const Filter = () => {

    return (
      <div className="d-flex my-2 border rounded p-3 justify-content-between align-items-center gap-2 flex-wrap flex-md-nowrap">
        <SymbolsWatchList show={showSymbols} setShow={setShowSymbols} symbols={symbols} />
        <div className="d-flex gap-3 text-12 align-items-start flex-wrap flex-md-nowrap">
          <PresetDateRangeFilter value={presetDateRangeFilter} setValue={setPresetDateRangeFilter} />
          <OpenedFilter value={openedFilter} setValue={setOpenedFilter} />
          {/* <SideFilter value={sideFilter} setValue={setSideFilter} /> */}
          <PositionTypeFilter value={positionTypeFilter} setValue={setPositionTypeFilter} />
          <StatusFilter value={statusFilter} setValue={setStatusFilter} />
          <div className="form-group">
            <StockSymbolFilter2 value={stockSymbolFilter} setValue={setStockSymbolFilter} names={symbolNames} />
            <label className="my-1">
              <input type="checkbox" checked={excludeStockSymbol} onChange={() => setExcludeStockSymbol(!excludeStockSymbol)} />
              <span className="ms-1">Exclude</span>
            </label>
          </div>
          <div className="align-self-start">
            <button type="button" className="btn btn-primary" onClick={() => updateList()}><ArrowClockwise /></button>
            <Loading />
          </div>
        </div>

        <TradesSummary trades={filteredData} livePrices={livePrices} />

        {api.data && api.data.length > 0 &&
          <div>{excelDownload.renderIcon(data)}</div>
        }
      </div>
    );

  }


  const toggleTradeSetupFilter = (setupId: number) => {
    if (setupFilter === setupId) {
      setSetupFilter(undefined);
      return;
    }
    setSetupFilter(setupId);
  }

  const toggleSecurityFilter = (securityName: string | undefined) => {
    if (!securityName || securityName === securityFilter) {
      setSecurityFilter(undefined);
      return;
    }
    setSecurityFilter(securityName);
  }

  return {
    filterComponent: Filter,
    filteredData,
    filters: {
      status: {value: statusFilter, set: setStatusFilter},
      side: {value: sideFilter, set: setSideFilter},
      positionType: {value: positionTypeFilter, set: setPositionTypeFilter},
      presetDateRange: {value: presetDateRangeFilter, set: setPresetDateRangeFilter},
      opened: {value: openedFilter, set: setOpenedFilter},
      setup: {value: setupFilter, set: setSetupFilter},
      security: {value: securityFilter, set: setSecurityFilter},
      statusReason: {value: statusReasonFilter, set: setStatusReasonFilter},
      stockSymbol: {value: stockSymbolFilter, set: setStockSymbolFilter},
      excludeSymbol: {value: excludeStockSymbol, set: setExcludeStockSymbol},
    },
    toggleSecurityFilter,
    toggleTradeSetupFilter,
    renderLivePrices,
    livePrices,
    reload: api.reload,
    apiState: api.apiState,
    error: api.error,
  }

}
