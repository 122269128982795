import React from 'react'
import { formatUSCurrency } from './Trades'
import { PointUpDownOrders } from './ChartOverlay'
import { NewYorkDate } from 'components/common/NewYorkDate'

export const OrderTable:React.FunctionComponent<{orders: PointUpDownOrders[] | undefined}> = ({orders}) => {
  return (
    <table className="table table-hover text-13" style={{ maxWidth: "800px" }}>
    <thead><tr>
      <th>Action</th>
      <th>Option Name</th>
      <th>Order Date</th>
      <th className="text-end">Order Price</th>
      <th className="text-end" style={{maxWidth: "125px"}}>Stock Price (at Time of Order)</th>
    </tr></thead>
    <tbody>
      {orders && orders.length > 0 ? orders.map((o, i) => <tr key={`oid-${i}`}>
        <td>{o.action}</td>
        <td>{o.optionName}</td>
        <td><NewYorkDate dateInMs={o.actualOrderDate * 1000} /></td>
        <td className="text-end">{formatUSCurrency(o.contractPrice)}</td>
        <td className="text-end">{formatUSCurrency(o.y)}</td>
      </tr>) : <tr>
        <td colSpan={6}>No Data Found</td>
      </tr>}
    </tbody>
  </table>

  )
}
