import {
  AnalysisInputParams,
  FilterUserAverageScoreRange,
} from "predictagram-lib";

import { Helper } from "_utils";
import { TradeSetupFilterOptions } from "../autotrade/TradeSetupForm";
import { StrategyFilterOptions } from "../analysis/strategy-profit/Filter";


export const processSearchOptions = async (searchOptions: TradeSetupFilterOptions | StrategyFilterOptions) => {

  const predictionIds = Helper.stringToArray(searchOptions.predictionIds);
  const predictionUserIds = Helper.stringToArray(searchOptions.predictionUserIds);

  // if (searchOptions.predictionIds?.length) {
  //   searchOptions.predictionIds = (searchOptions.predictionIds as any as string).split(',').map(v => +v);
  // } else {
  //   searchOptions.predictionIds = [];
  // }
  // if (searchOptions.predictionUserIds?.length) {
  //   searchOptions.predictionUserIds = (searchOptions.predictionUserIds as any as string).split(',').map(v => +v);
  // } else {
  //   searchOptions.predictionUserIds = undefined;
  // }

  searchOptions.predictionIds = predictionIds;
  searchOptions.predictionUserIds = predictionUserIds;
  
  const arrayToInts = (field: string) => {
    const opts = searchOptions as any;
    if (opts[field] && opts[field]?.length) {
      opts[field] = opts[field].map((v: any) => +v);
    } else {
      opts[field] = []
    }
  }

  if (searchOptions.openSignalSetup && searchOptions.openSignalSetup.signals) {
    searchOptions.openSignalSetup.signals = searchOptions.openSignalSetup.signals.filter(s => s !== undefined );
  }

  if (searchOptions.closeSignalSetup && searchOptions.closeSignalSetup.signals) {
    searchOptions.closeSignalSetup.signals = searchOptions.closeSignalSetup.signals.filter(s => s !== undefined );
  }

  for (let setup of [searchOptions.openSignalSetup, searchOptions.closeSignalSetup]) {
    (setup?.signals || []).forEach((s, index) => {
      if (!s.type) {
        (setup as any).signals = setup?.signals.filter((v, index2) => index2 !== index);
      }
    });
    if (!setup?.signals?.length) {
      (setup as any).signals = [];
      setup = undefined;
    }
  }

  arrayToInts('predictionTypes');

  const score = +(searchOptions.last30dScore);
  if (score > 0) {
    searchOptions.userAverageScore = {
      days: 30,
      minScoreRaw: score,
    } as any;
  } else if (score < 0) {
    searchOptions.userAverageScore = {
      days: 30,
      maxScoreRaw: score * -1,
    } as any;
  } else {
    delete searchOptions.userAverageScore;
  }

  for (const k in searchOptions) {
    const v = (searchOptions as any)[k];
    if (v && !Array.isArray(v) && !isNaN(Number(v)) && Number(v) !== 0 && v !== true && v !== false) {
      (searchOptions as any)[k] = +v;
    }
  }

}

export const getAnalysisInputParams = (options: StrategyFilterOptions | TradeSetupFilterOptions): Partial<AnalysisInputParams> => {

  const extraSymbolIds = options.extraSymbolIds ? options.extraSymbolIds.map((s) => parseInt(s.toString())) : undefined;

  const inputParams: Partial<AnalysisInputParams> = {
    aheadTimeSecs: options.aheadTimeSecs,
    prevCurDayPriceGap: options.prevCurDayPriceGap,
    closeStepSizeRate: options.closeStepSizeRate,
    endHourEst: options.endHourEst,
    closeSignalSetup: options.closeSignalSetup,
    endTime: options.endTime,
    groupDirectionType: options.groupDirectionType,
    openSignalSetup: options.openSignalSetup,
    openTriggerType: options.openTriggerType,
    predictionIds: options.predictionIds?.length ? options.predictionIds.join(',') : undefined as any,
    predictionUserIds: options.predictionUserIds?.length ? options.predictionUserIds.join(',') : undefined as any,
    predictionTypes: options.predictionTypes,
    startHourEst: options.startHourEst,
    startTime: options.startTime,
    stockSymbolId: options.stockSymbolId,
    stopLossRate: options.stopLossRate,
    takeProfitRate: options.takeProfitRate,
    userAverageScore: options.userAverageScore,
    windowMinElements: options.windowMinElements,
    windowMaxElements: options.windowMaxElements,
    windowTimeSecs: options.windowTimeSecs,
    openTriggerDirectionTypes: options.openTriggerDirectionTypes,
    predictionFirstMinStepSizeMoveMax: options.predictionFirstMinStepSizeMoveMax,
    extraSymbolIds: extraSymbolIds,
  };

  return inputParams;
}

export const getLast30dScore = (userAverageScore: FilterUserAverageScoreRange | undefined) => {
  if (!userAverageScore) {
    return undefined;
  }

  if (userAverageScore.minScoreRaw) {
    return userAverageScore.minScoreRaw
  }

  if (userAverageScore.maxScoreRaw) {
    return userAverageScore.maxScoreRaw * -1
  }

}
