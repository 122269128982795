import React, { useEffect } from 'react'
import ReactDatePicker from 'react-datepicker';


export interface IDateRangeFilter {
  startTime?: number,
  endTime?: number
}

interface IProps {
  value: IDateRangeFilter | undefined,
  setValue: (v: IDateRangeFilter) => void,
}




export const OpenedFilter: React.FunctionComponent<IProps> = ({value, setValue}) => {

  useEffect(()=>{},[value]);

  return (
    <div className="d-flex gap-2">

      <div className="d-flex flex-column">
        <span className="fw-bold">Start Date</span>
        <ReactDatePicker
          dateFormat="MM/dd/yyyy"
          onChange={(date: Date) => {
            setValue({
              startTime: date.getTime() / 1000,
              endTime: value?.endTime
            });
          }}
          selected={value?.startTime ? new Date(value.startTime * 1000) : undefined}
        />
      </div>

      <div className="d-flex flex-column">
        <span className="fw-bold">End Date</span>
        <ReactDatePicker
          dateFormat="MM/dd/yyyy"
          onChange={(date: Date) => {
            setValue({
              startTime: value?.startTime,
              endTime: date.getTime() / 1000
            });
          }}
          selected={value?.endTime ? new Date(value.endTime * 1000) : undefined}
        />
      </div>

    </div>
  )
}
