import { getAnalysisInputParams, getLast30dScore } from 'components/admin/common/Criteria';
import { StockDirectionType as DirectionType, FilterUserAverageScoreRange, ISignalAlert, MathEx, NewYorkTz, StockHelper, StockSignalsEnum } from 'predictagram-lib';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { adminApiServiceCommon, adminSignalAlertApiService } from 'services/AdminApiService';
import { StrategyFilterOptions } from '../strategy-profit/Filter';
import { Spinner } from 'components/common/Spinner';
import { StrategyProfitModel as model } from 'models/strategy-profit.model';
import { NewYorkDate } from 'components/common/NewYorkDate';
import { Helper } from '_utils';
import { CumulativeChartLines, CumulativeChartSignal, ICumeDataSearchOptions, getChartOptions, getSignalDefaultLines, linesAndAnnotationsOptions } from 'components/admin/common/helper';
import { coloredSignalNameMapper } from 'components/admin/common/ColoredSignal';
import {CumulativeChart} from "../../common/CumulativeChart";


type TradeDataType = {
  directionSum: number,
  open: {
    time: number
  }
}

const getExtraSignalsFromTrades = (trades: TradeDataType[]): CumulativeChartSignal[] => {
  if (!trades || trades.length === 0) return [];

  const signals = trades.map(trades=>{
    const signal: CumulativeChartSignal = {
      direction: trades.directionSum < 0 ? DirectionType.DOWN : DirectionType.UP,
      x: trades.open.time,
      color: "#ffff99"
    }
    return signal;
  })
  return signals;
}


export const AdminCumeHistoryPage: React.FunctionComponent = () => {
  const signalAlertId = parseInt(useParams().signalAlertId as string, 10);

  const [strategyFilterOptions, setStrategyFilterOptions] = useState<Partial<StrategyFilterOptions> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const [rows, setRows] = useState<any[]>([]);

  const CHART_COUNT = 30;

  const dateRange = (dateString: string) => {
    const date = new Date(dateString + `T00:00:00-04:00`);
    return {
      start: StockHelper.workingHours(date).start,
      end: StockHelper.workingHours(date).end,
    }
  }

  useEffect(() => {
    (async () => {

      setLoading(true);

      // signal alert settings
      const signalAlert: ISignalAlert = await adminSignalAlertApiService.getById(signalAlertId, true);
      const analysisInputParams = getAnalysisInputParams(signalAlert.data as StrategyFilterOptions);
      const strategyFilterOptions: Partial<StrategyFilterOptions> = {
        ...analysisInputParams,
        last30dScore: getLast30dScore(signalAlert.data.userAverageScore),
        alertName: signalAlert.name,
        alertEmails: signalAlert.emails,
        alertSendCloseSignals: signalAlert.options?.sendCloseSignals || false,
        startTime: Helper.addDays(NewYorkTz.getDateMidnight(new Date()).getTime(), -60).getTime() / 1000,
        endTime: NewYorkTz.getDateMidnight(new Date()).getTime() / 1000,
        outputOptions: {
          returnTrades: true,
        }
      }

      const searchOptions = Object.assign({}, strategyFilterOptions);

      // get report
      const result: any[] = await adminApiServiceCommon.analysisStrategyProfitDaily(searchOptions);
      const rep: any[] = [];

      for (const day of result) {
        const scores: any = day.result.stats.score;
        const amount = scores.profitAmountSum
          + scores.lossAmountSum
          + scores.atCloseAmountSum
          + scores.atExitSignalAmountSum
          + scores.atExitStepSizeAmountSum;
        const totalTrades = scores.totalCount;
        const tradeWinRate = totalTrades ? MathEx.round(day.result.stats.trades.profitable / totalTrades, 4) : 0;
        const tradeData: TradeDataType[] = day.result.trades;
        if (totalTrades > 0) {
          rep.push({
            date: day.date,
            overallPct: model.toFixed(scores.overall * 100, 4),
            posDirProfitPct: model.toFixed(scores.positiveDirection * 100, 4),
            negDirProfitPct: model.toFixed(scores.negativeDirection * 100, 4),
            trades: totalTrades,
            tradeWinRate: tradeWinRate,
            overallAmount: model.toFixed(amount, 3),
            profitPct: totalTrades ? model.toFixed(scores.profitCount / totalTrades * 100, 2) : 0,
            profitAmount: model.toFixed(scores.profitAmountSum, 3),
            lossPct: totalTrades ? model.toFixed(scores.lossCount / totalTrades * 100, 2) : 0,
            lossAmount: model.toFixed(scores.lossAmountSum, 3),
            closePct: totalTrades ? model.toFixed(scores.atCloseCount / totalTrades * 100, 2) : 0,
            closeAmount: model.toFixed(scores.atCloseAmountSum, 3),
            signalPct: totalTrades ? model.toFixed(scores.atExitSignalCount / totalTrades * 100, 2) : 0,
            signalAmount: model.toFixed(scores.atExitSignalAmountSum, 3),
            stepSizePct: totalTrades ? model.toFixed(scores.atExitStepSizeCount / totalTrades * 100, 2) : 0,
            stepSizeAmount: model.toFixed(scores.atExitStepSizeAmountSum, 3),
            tradeData: tradeData,
          })
        }
      }
      setRows(rep);
      setStrategyFilterOptions(strategyFilterOptions);
      setLoading(false);
    })()

  }, [signalAlertId])

  if (loading) {
    return <Spinner minHeight={10} />
  }

  return (
    <div>
      <div className="page-title my-3">Cumulative History Page</div>

      {rows.sort((a, b) => b.date.localeCompare(a.date)).slice(0, CHART_COUNT).map((r, i) => {
        const dates = dateRange(r.date);

        const s: ICumeDataSearchOptions = {
          startTimePredMade: dates.start.getTimeSec(),
          endTimePredMade: dates.end.getTimeSec(),
          extraSymbolIds: [],
          predictionTypes: strategyFilterOptions?.predictionTypes,
          stockSymbolId: strategyFilterOptions?.stockSymbolId as number,
          userAverageScore: { days: strategyFilterOptions?.last30dScore as number } as FilterUserAverageScoreRange,
          openSignalSetup: strategyFilterOptions?.openSignalSetup,
        }

        const sigs = strategyFilterOptions?.openSignalSetup?.signals.map(s=>{return s.type}) || [];
        const showLines: CumulativeChartLines[] = getSignalDefaultLines(sigs);
        const showBaseSignals: StockSignalsEnum[] = (strategyFilterOptions?.openSignalSetup?.signals || []).map(s => s.type);

        return <div key={`key-${r.date}`}>
          <div className="d-flex flex-column gap-2 mx-5">
            <table className="table table-hover">
              <thead>
                <th>Name</th>
                <th>Signals</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th className="text-end">Analysis Trades</th>
                <th className="text-end">Trade Win Rate</th>
              </thead>
              <tbody>
                <tr>
                  <td>{signalAlertId} {strategyFilterOptions?.alertName}</td>
                  <td>{showBaseSignals.map((v,i)=>coloredSignalNameMapper(v, i))}</td>
                  <td><NewYorkDate dateInMs={s.startTimePredMade * 1000} /></td>
                  <td><NewYorkDate dateInMs={s.endTimePredMade * 1000} /></td>
                  <td className="text-end">{r.trades}</td>
                  <td className="text-end">{(r.tradeWinRate * 100).toFixed(2)}%</td>
                </tr>
              </tbody>
            </table>

            <div style={{ width: "100%", height: "550px" }}>

              <CumulativeChart
                cumeDataSearchOptions={s}
                params={{
                  // showBaseSignals: [],
                  showLines,
                  extraSignals: getExtraSignalsFromTrades(r.tradeData),
                  showOptions: getChartOptions(linesAndAnnotationsOptions),
                }}
              />

            </div>
          </div>
        </div>
      })}
    </div>
  )
}
