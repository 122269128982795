import React from "react";
import {ErrorMessage, Field, useFormikContext} from "formik";

// @TODO: Formik is dead?:
// https://github.com/jaredpalmer/formik/discussions/3526

// Formik is missing a hook to map easily input value to selected option,
// "setFieldValue" map output value and it works fine, but there is not way to populate back properly easily

interface DropDownType<Label,Value> {
  // errors: any;
  // touched: any;
  options: {label:Label, value:Value}[];
  label: string | JSX.Element;
  name: string;
  // defaultValue?: Value,
  // value?: Value,
  // setFieldValue: any; // formik setFieldValue,
  // defaultItem?: {key: Label, value: Value};
  // defaultKey?: Label,

  // [key: string]: any;
}

function DropDownGeneric<Label,V>(props: DropDownType<Label,V>, ...rest:any) {
  const context = useFormikContext();
  // const fullMap:Map<Label, V> = new Map();
  // if (props.defaultItem) {
  //   fullMap.set(props.defaultItem.key, props.defaultItem.value);
  // }
  // props.enumMap.forEach((v,l)=>fullMap.set(l, v));
  // let defaultIndex = 0;
  // if (props.defaultKey) {
  //     const res = Array.from(fullMap.keys()).findIndex((l) => l === props.defaultKey);
  //     if (res) {
  //       defaultIndex = res;
  //     }
  // }
  // @TODO: need to think more about the population
  let valForm: any = undefined; // props.value;
  const mapBack = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(e.target.value);
    return props.options[index].value;
    // return fullMap.get(list[index])
  }
  let onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // context.handleChange(e);
    const val = mapBack(e);
    // console.debug({val});
    context.setFieldValue(props.name, val);
    // valForm = val;
  };
  if (rest.onChange) {
    onChange = (e:any)=>{
      return rest.onChange(mapBack(e))
    }
  }
  // // const valIn = (context.initialValues as any)?.[props.name];
  // const valIn = (context.initialValues as any).openSignalSetup.signals[0]['options']['repeatedSignal'];
  // const val = props.options.findIndex((v)=>v.value===valIn)||0;
  // console.debug({name:props.name,valIn, val, vals: (context.initialValues as any).openSignalSetup.signals});

  if (valForm===undefined) {
      const data = context.getFieldProps({name: props.name});
      valForm = data.value;
  }
  // if (valForm===undefined && props.defaultValue!==undefined) {
  //   valForm = props.defaultValue;
  // }
  const index=props.options.findIndex((v)=>v.value==valForm);
  //
  // console.debug({index: index, valForm, name: props.name, vals: context.initialValues});
  // // console.debug({setVal: valForm});
  return (
    <div className="form-group">
      {props.label && props.label !=="" && <>{props.label}</>}
      {/*<select*/}
      <Field as="select"

        name={props.name}
        // className={"form-control" + (props.errors && props.errors[props.name] && props.touched && props.touched[props.name] ? " is-invalid" : "")}
        className={"form-control"}
        onChange={onChange}
        // value={index}
        value={index}
             
        {...rest}
      >
        {/*{props.defaultItem && <option value={props.defaultItem.key}>{props.defaultItem.value}</option>}*/}

        {props.options.map((opt, i) => {
          return (
            <option key={`code_${i}`} value={i}
                    // selected={val===opt.value}
              >
            {opt.label as any}
          </option>
          )
        })}
      </Field>
      {/*</select>*/}
      {/*{props.errors && <ErrorMessage name={props.name} component="div" className="invalid-feedback" />}*/}
    </div>
  );
}

export { DropDownGeneric };
