import React from 'react'
import { StrategyFilterOptions } from './Filter'
import { StrategyProfitModel as model } from 'models/strategy-profit.model'
import { SymbolIdsDropdown } from 'components/common/SymbolIdsDropdown';
import { DropDown } from 'components/common/form/DropDown';
import { MultiSymbolsDropDown } from 'components/common/analysis/MultiSymbolsDropDown';
import { PredictionTypesDropdown } from 'components/common/PredictionsDropDown';
import { FieldWithError } from 'components/common';
import { AnalysisFilters } from 'components/common/analysis/analysis.filters';
import { DatePickerTzEst } from 'components/common/DatePickerTz';
import { TradeSetupFilterOptions } from 'components/admin/autotrade/TradeSetupForm';
import { DropDownNumber } from 'components/common/form/DropDownNumber';
import {Analysis, StockStatsIntervalEnum} from "predictagram-lib";

interface IProps {
  errors: any,
  touched: any,
  values: StrategyFilterOptions | TradeSetupFilterOptions,
  setFieldValue: any,
  onOpenTriggerDirection?: (direction: "up" | "dn") => void,
}

export const CumulativeForm: React.FunctionComponent<IProps> = ({
  errors,
  touched,
  values,
  setFieldValue,
  onOpenTriggerDirection,
}) => {

  const enabledExtraSymbolIds = (values.extraSymbolIds && values.extraSymbolIds?.length > 0) || false;

  const DateRange = () => {
    return (
      <div className="d-flex justify-content-start gap-2 border rounded p-3">

        {/* calendar controls */}
        <div className="d-flex flex-column">
          <span className="fw-bold">Start Date EST (inclusive)</span>
          <DatePickerTzEst
            dateFormat="MM/dd/yyyy"
            onChange={(date: Date) => {
              setFieldValue("startTime", date.getTime() / 1000);
            }}
            selected={new Date(values.startTime * 1000)}
          />
          <br />
          <span className="fw-bold">End Date EST (inclusive)</span>
          <DatePickerTzEst
            dateFormat="MM/dd/yyyy"
            onChange={(date: Date) => {
              setFieldValue("endTime", date.getTime() / 1000);
            }}
            selected={new Date(values.endTime * 1000)}
          />
        </div>

        {/* start and end hour */}
        <div className="d-flex flex-column">
          <DropDown
            enumMap={model.startHour}
            errors={errors}
            touched={touched}
            label="Start Hour EST (inclusive)"
            name={'startHourEst'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('startHourEst', Number(e.target.value));
            }}
          />
          <DropDown
            enumMap={model.endHour}
            errors={errors}
            touched={touched}
            label="End Hour EST (inclusive)"
            name={'endHourEst'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('endHourEst', Number(e.target.value));
            }}
          />
        </div>
      </div>

    );
  }


  return (
    <div className="d-flex flex-column flex-md-row gap-3 rounded border p-3">
      {/* Left Column */}
      <div className="d-flex flex-column gap-3">
        <SymbolIdsDropdown name="stockSymbolId" errors={errors} touched={touched} />

        <MultiSymbolsDropDown errors={errors} touched={touched} name={'extraSymbolIds'} enableByDefault={enabledExtraSymbolIds} />

        <DropDown enumMap={AnalysisFilters.UsersScore}
          errors={errors}
          touched={touched}
          label="Last30d Score" name="last30dScore"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            setFieldValue('last30dScore', value === 'All' ? undefined : Number(value));
          }}
        />

        {/* center column */}
        <div className="d-flex flex-column gap-3">
          <FieldWithError errors={errors} touched={touched} fieldName="predictionIds" label="Prediction IDs" />
          <FieldWithError errors={errors} touched={touched} fieldName="predictionUserIds" label="User IDs" />
          <DropDownNumber setFieldValue={setFieldValue} enumMap={model.closeStepSizeRateList} defaultItem={{ key: '', value: 'None' } as any} errors={errors} touched={touched} label="Step Size First Min Move" name={'predictionFirstMinStepSizeMoveMax'} />
        </div>


      </div>


      {/* Right Column */}
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-row justify-content-start justify-content-md-between gap-2">
          <DropDown
            style={{ height: "50px" }}
            errors={errors}
            touched={touched}
            multiple={true}
            enumMap={model.directionTypeList}
            label={'Open Trigger Direction'}
            name={'openTriggerDirectionTypes'}
            onChange={(v: any) => {
              const openTriggerTypes = model.toIntArrayOrDefault(Array.from(v.target.selectedOptions).map((o: any) => o.value));
              setFieldValue(`openTriggerDirectionTypes`, openTriggerTypes);
              if (onOpenTriggerDirection && openTriggerTypes.length > 0) {
                onOpenTriggerDirection(openTriggerTypes[0] === 1 ? "up" : "dn");
              }
            }}
          />

          <PredictionTypesDropdown name="predictionTypes" showDefault={false} errors={errors} touched={touched} multiple={true} />

        </div>

        <DateRange />

        <DropDown enumMap={new Map([
          [Analysis.StockSource.TIINGO, 'Tiingo'],
          [Analysis.StockSource.IBKR, 'IBKR'],
        ])}
                  errors={errors}
                  touched={touched}
                  label="Stock Source"
                  name="inputOptions[stockSource]"
        />
      </div>
    </div>
  )
}
