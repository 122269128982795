import {
    IQuote,
    LinePoints, SignalOptions, SignalOptionsFull,
    SignalPatternInput,
    SignalPrice,
    StockDirectionType as DirectionType,
    StockDirectionType
} from "../../interface";
import {MathEx} from "../../utils";
import {ValidationError} from "../../error";

// Interrupter Green candle= IGC
//
// Interrupter Red Candle = IRC
//
// Previous Candle's Bottom Quartile price=Prev BQ
// First Candle = FC


export class CandlePatternsSignal {

// If current minute has a high/low which crosses the threshold (prev minute threshold),
// I will close trade using the threshold

    public static currPrice1stCandleBody(input: SignalPatternInput): null|SignalPrice {
        if (!CandlePatternsSignal.filterVolumeOption(input)) {
            return null;
        }
        const redOrGreen = CandlePatternsSignal.hasPrevFirstRedOrGreen(input.currPos, input.stock, input.options?.candleSizePriceRate||0.001);
        return CandlePatternsSignal.currPriceCandleBody(input.currPos, input.stock, redOrGreen);
    }

    public static currPrice1stCandleBody2ndCandleSteam(input: SignalPatternInput) {
        if (!CandlePatternsSignal.filterVolumeOption(input)) {
            return null;
        }
        const redOrGreen = CandlePatternsSignal.currPrice1stCandleBody(input);
        return CandlePatternsSignal.currPriceCandleBody2ndCandleSteam(input.currPos, input.stock, redOrGreen);
    }

    public static currPriceIntrptCandleBody(input: SignalPatternInput): null|SignalPrice {
        if (!CandlePatternsSignal.filterVolumeOption(input)) {
            return null;
        }
        const redOrGreen = CandlePatternsSignal.hasPrevInterruptionCandle(input.currPos, input.stock);
        return CandlePatternsSignal.currPriceCandleBody(input.currPos, input.stock, redOrGreen);
    }

    public static currPriceIntrptCandleBody2ndCandleSteam(input:SignalPatternInput) {
        if (!CandlePatternsSignal.filterVolumeOption(input)) {
            return null;
        }
        const prevCond = CandlePatternsSignal.currPriceIntrptCandleBody(input);
        return CandlePatternsSignal.currPriceCandleBody2ndCandleSteam(input.currPos, input.stock, prevCond);
    }

    public static currPriceOpenRangeHighLow(input: SignalPatternInput): null|SignalPrice {
        const range = input.options.openRangeAverage;
        if (!range) {
            throw new ValidationError('openRangeAverage is missing');
        }
        const res = CandlePatternsSignal.candleCrossPriceByCandleRateVolume(input, range.high, range.high);
        if (res) {
            return res;
        }
        return CandlePatternsSignal.candleCrossPriceByCandleRateVolume(input, range.low, range.low);
    }

    public static currPriceVwap(input: SignalPatternInput): null|SignalPrice {
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, input.options.lines?.vwap as LinePoints);
    }

    public static currPriceSma120(input: SignalPatternInput): null|SignalPrice {
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, input.options.lines?.sma120 as LinePoints);
    }

    public static currPriceMinMax2hVd30m160pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m160pct.min, minMax.stats2hVd30m160pct.max);
    }

    public static currPriceMin2hVd30m160pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m160pct.min);
    }

    public static currPriceMax2hVd30m160pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m160pct.max);
    }

    public static currPriceMinMax2hVd30m220pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m220pct.min, minMax.stats2hVd30m220pct.max);
    }

    public static currPriceMin2hVd30m220pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m220pct.min);
    }

    public static currPriceMax2hVd30m220pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m220pct.max);
    }

    public static currPriceMinMax2hVd30m400pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m400pct.min, minMax.stats2hVd30m400pct.max);
    }

    public static currPriceMin2hVd30m400pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m400pct.min);
    }

    public static currPriceMax2hVd30m400pct(input: SignalPatternInput): null|SignalPrice {
        const minMax = CandlePatternsSignal.getMinMax(input);
        return CandlePatternsSignal.candleCrossLineByCandleRateVolume(input, minMax.stats2hVd30m400pct.max);
    }

    private static getMinMax(input: SignalPatternInput) {
        const minMax = input.options.minMax2hVd30m;
        if (!minMax) {
            throw new ValidationError('empty minMax2hVd30m')
        }
        return minMax;
    }

    public static currPriceOpenPrice(input: SignalPatternInput): null|SignalPrice {
        // skip first point
        if (input.currPos<1) {
            return null;
        }
        return CandlePatternsSignal.candleCrossPriceByCandleRateVolume(input, input.stock[0].c, input.stock[1].c);
    }

    public static currPriceSupRes(input: SignalPatternInput): null|SignalPrice {
        const supRes = input.options.lines?.supRes;
        if (!supRes) {
            throw new ValidationError('empty supRes')
        }
        const curr = input.stock[input.currPos];
        const t = curr.t;
        for (const lsr of supRes) {
            if (t<lsr.tLast) {
                continue;
            }
            for (const m of [lsr.r1, lsr.r2, lsr.s1, lsr.s2]) {
                const res = CandlePatternsSignal.candleCrossPriceByCandleRateVolume(input, m, m);
                if (res) {
                    return res;
                }
            }
        }
        return null;
    }

    public static currPricePrevDayClosePrice(input: SignalPatternInput): null|SignalPrice {
        const price = input.options.prevDayClosePrice;
        if (!price) {
            throw new ValidationError('prevDayClosePrice is missing');
        }
        return CandlePatternsSignal.candleCrossPriceByCandleRateVolume(input, price, price);
    }

    public static ema6CdlDay(input: SignalPatternInput): null|SignalPrice {
        const repeat = input.options.repeatedSignal||false;
        if (!input.options.lines) {
            throw new ValidationError('lines are empty')
        }
        return CandlePatternsSignal.calcEmaSmaSignalStock(input.stock, input.currPos, input.options.lines.ema6day, input.options);
    }

    public static ema12CdlDay(input: SignalPatternInput): null|SignalPrice {
        if (!input.options.lines) {
            throw new ValidationError('lines are empty')
        }
        return CandlePatternsSignal.calcEmaSmaSignalStock(input.stock, input.currPos, input.options.lines.ema12day, input.options);
    }

    public static ema26CdlDay(input: SignalPatternInput): null|SignalPrice {
        if (!input.options.lines) {
            throw new ValidationError('lines are empty')
        }
        return CandlePatternsSignal.calcEmaSmaSignalStock(input.stock, input.currPos, input.options.lines.ema26day, input.options);
    }

    protected static candleCrossLineByCandleRateVolume(input: SignalPatternInput, ...lines:LinePoints[]):null|SignalPrice {
        for (const line of lines) {
            if (!line) {
                throw new ValidationError('line is empty');
            }
            const price = line.y[input.currPos];
            const pricePrev = line.y?.[input.currPos - 1];
            if (!pricePrev) {
                return null;
            }
            const res = CandlePatternsSignal.candleCrossPriceByCandleRateVolume(input, price, pricePrev);
            if (res) {
                return res;
            }
        }
        return null;
    }

    public static candleCrossPriceByCandleRateVolume(input: SignalPatternInput, price:number, pricePrev:number):null|SignalPrice {
        const rate = input.options.candleSizeRate;
        if (rate==null) {
            throw new ValidationError('candleSizeRate is empty');
        }
        const currPos = input.currPos;
        const curr = input.stock?.[currPos];
        const prev = input.stock?.[currPos-1];
        if (!curr || !prev) {
            return null;
        }
        const currThresholdTop = MathEx.round(curr.l+(curr.h-curr.l)*rate, 3);
        const currThresholdBottom = MathEx.round(curr.h-(curr.h-curr.l)*rate, 3);

        const prevThresholdTop = MathEx.round(prev.l+(prev.h-prev.l)*rate, 3);
        const prevThresholdBottom = MathEx.round(prev.h-(prev.h-prev.l)*rate, 3);

        if (!CandlePatternsSignal.filterVolumeOption(input)) {
            return null;
        }

        // if (prevThresholdTop>pricePrev && currThresholdTop<=price) {
        if (prev.h>=price && currThresholdTop<price && curr.c<price) {
            return {
                dir: DirectionType.DOWN,
                price: curr.c,
                y: price,
            }
        }

        // if (prevThresholdBottom<pricePrev && currThresholdBottom>=price) { // candle should be above line
        if (prev.l<=price && currThresholdBottom>price && curr.c>price) {
            return {
                dir: DirectionType.UP,
                price: curr.c,
                y: price,
            }
        }

        return null;

    }

    public static currPriceCandleSizeRate(input: SignalPatternInput): null|SignalPrice {
        const options = input.options;
        const currPos = input.currPos;
        const stock = input.stock;
        const rate = options?.candleSizeRate;
        if (rate==null) {
            throw new ValidationError('invalid candleSizeRate');
        }
        if (currPos<1 || currPos>stock.length-1 || stock.length<2) {
            return null;
        }

        const curr = stock[currPos];
        const prev = stock[currPos-1];
        const threshold = MathEx.round(prev.l+(prev.h-prev.l)*rate, 3);
        // prev is green - curr is potentially red
        if (prev.c>prev.o) {
            if (curr.l<threshold) {
                return {
                    dir: DirectionType.DOWN,
                    price: curr.c,
                    y: threshold,
                }
            }
        }

        // prev is red - curr is potentially green
        if (prev.c<prev.o) {
            if (curr.h>threshold) {
                return {
                    dir: DirectionType.UP,
                    y: threshold,
                    price: curr.c,
                }
            }
        }
        return null;
    }

    public static currPriceCandleBody(currPos: number, stock: Readonly<IQuote[]>, prevDir: DirectionType|undefined): null|SignalPrice {
        if (!prevDir) {
            return null;
        }
        const curr = stock[currPos];
        const prev = stock[currPos-1];
        if (prevDir===DirectionType.UP) {
            if (curr.o>prev.c) {
                return {
                    dir: prevDir,
                    price: curr.o,
                };
            }
            if (curr.c>prev.c) {
                return {
                    dir: prevDir,
                    price: curr.c,
                    y: prev.c,
                };
            }
        } else if (prevDir===DirectionType.DOWN) {
            if (curr.o<prev.c) {
                return {
                    dir: prevDir,
                    y: curr.o,
                    price: curr.c,
                };
            }
            if (curr.c<prev.c) {
                return {
                    dir: prevDir,
                    price: curr.c,
                    y: prev.c,
                };
            }
        }

        return null;

    }

    public static sameDirectionCandles(input: SignalPatternInput): null|SignalPrice {
        const stock = input.stock;
        const currPos = input.currPos;
        const options = input.options;
        const curr = stock?.[currPos];
        if (!curr) {
            return null;
        }
        if (!options.candlesCount) {
            throw new ValidationError('invalid candlesCount');
        }
        const dir = (q:IQuote)=>{
            return q.c>=q.o ? DirectionType.UP : DirectionType.DOWN;
        }

        const primaryDir = dir(curr);
        let count = 1;
        for (let i=currPos-1;i>=0;i--) {
            if (dir(stock[i])!==primaryDir) {
                break;
            }
            count++;
        }
        if (count>=options.candlesCount) {
            return {
                // invert signal meaning
                dir: primaryDir===DirectionType.UP ? DirectionType.DOWN : DirectionType.UP,
                price: curr.c
            }
        }
        return null;
    }

    protected static currPriceCandleBody2ndCandleSteam(currPos: number, stock: Readonly<IQuote[]>, prevCond: SignalPrice|null) {
        // const redOrGreen = this.currPrice1stCandleBody(currPos, stock);
        if (!prevCond) {
            return null;
        }
        const curr = stock[currPos];
        const prev2 = stock[currPos-2];
        if (prevCond.dir===DirectionType.UP) {
            if (curr.c>prev2.h) {
                return prevCond;
            }
        } else if (prevCond.dir===DirectionType.DOWN) {
            // prev is down
            if (curr.c<prev2.l) {
                return prevCond;
            }
        }
        return null;
    }

    public static hasPrevFirstRedOrGreen(currPos: number, stock: Readonly<IQuote[]>, candleSizePriceRate:number) {
        if (currPos<3 || stock.length<3) {
            return;
        }
        const curr = stock[currPos];
        const minBodyHeight = curr.c*candleSizePriceRate;
        const minus1 = stock[currPos-1];
        const minus2 = stock[currPos-2];
        const minus3 = stock[currPos-3];

        const bodyHeight = Math.abs(minus1.c - minus1.o);
        const isHighEnough = bodyHeight>minBodyHeight;
        if (!isHighEnough) {
            return;
        }
        // - green, higher body, has red behind
        if (minus1.c>minus1.o && minus1.c>Math.max(minus2.o, minus2.c) && (minus2.c<minus2.o || minus3.c<minus3.o)) {
            return DirectionType.UP;
        }

        // - green, higher body, has green behind
        if (minus1.c<minus1.o && minus1.c<Math.min(minus2.o, minus2.c) && (minus2.c>minus2.o || minus3.c>minus3.o)) {
            return DirectionType.DOWN;
        }

        return;
    }

    public static hasPrevInterruptionCandle(currPos: number, stock: Readonly<IQuote[]>) {
        if (currPos < 3 || stock.length < 3) {
            return;
        }
        const curr = stock[currPos];
        const minus1 = stock[currPos-1];
        const minus2 = stock[currPos-2];
        const minus3 = stock[currPos-3];
        // 1 green, 3/4 close, 2 sequential red
        if (minus1.c>minus1.o && minus1.c>(minus2.l+0.75*(minus2.h-minus2.l)) && minus2.c<minus2.o && minus3.c<minus3.o) {
            return DirectionType.UP;
        }
        // 1 red, 1/4 close, 2 sequential green
        if (minus1.c<minus1.o && minus1.c<(minus2.o+0.25*(minus2.c-minus2.o)) && minus2.c>minus2.o && minus3.c>minus3.o) {
            return DirectionType.DOWN;
        }

        return;

    }

    public static calcEmaSmaSignalStock(stock: Readonly<IQuote[]>,currPos: number, ema: LinePoints, options: SignalOptions): null|SignalPrice {
        const repeat = options.repeatedSignal||false;
        const prevEma = ema.y?.[currPos-1];
        const prevStock = stock?.[currPos-1];
        if (!prevEma || !prevStock) {
            return null;
        }
        const currEma = ema.y[currPos];
        const currStock = stock[currPos];

        const dist = options.distanceRate;
        if (dist!=null) {
            const priceAdj = currStock.c*dist;
            const emaRangeHigh = currEma+priceAdj;
            const emaRangeLow = currEma-priceAdj;

            const priceAdjPrev = prevStock.c*dist;
            const emaRangeHighPrev = prevEma+priceAdjPrev;
            const emaRangeLowPrev = prevEma-priceAdjPrev;

            if (repeat) {
                if (emaRangeHigh>=currStock.h && currStock.h>=currEma) {
                    return { price: currStock.c, y: currStock.h, dir: StockDirectionType.DOWN};
                } else if (emaRangeLow<=currStock.l && currStock.l<=currEma) {
                    return { price: currStock.c, y: currStock.l, dir: StockDirectionType.UP};
                }
            } else {
                if ( emaRangeHighPrev<prevStock.h && emaRangeHigh>=currStock.h) {
                    return { price: currStock.c, y: currStock.h, dir: StockDirectionType.DOWN};
                } else if (emaRangeLowPrev>prevStock.l && emaRangeLow<=currStock.l) {
                    return { price: currStock.c, y: currStock.l, dir: StockDirectionType.UP};
                }
            }
        } else {
            if ((prevEma < prevStock.h || repeat) && currEma >= currStock.h) {
                return {price: currStock.c, y: currStock.h, dir: StockDirectionType.DOWN};
            } else if ((prevEma > prevStock.l || repeat) && currEma <= currStock.l) {
                return {price: currStock.c, y: currStock.l, dir: StockDirectionType.UP};
            }
        }
        return null;
    }

    public static filterVolumeOption(input: SignalPatternInput) {
        if (input.options.volumeRate==null) {
            return true;
        }

        const currPos = input.currPos;
        const curr = input.stock?.[currPos];
        const prev = input.stock?.[currPos-1];
        if (!curr || !prev) {
            return false;
        }
        const smaC = input.options?.volumeSmaCount||7;
        let sum = 0;
        let count = 0;
        for (let i=1;i<=smaC && currPos-i>=0;i++) {
            count++;
            sum+=input.stock[currPos-i].v;
        }
        if (!count) {
            return false;
        }
        const  avg = sum/count;
        if (curr.v<avg*input.options.volumeRate) {
            return false;
        }
        return true;
    }
}
