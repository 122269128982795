import {SignalsData} from "./signals.data";
import {
    StockDirectionType,
    IQuote,
    IQuoteFull,
    LinePoints,
    StockCalculatedLines,
    PointUpDown,
    SignalOptionsFull,
    SignalSetup, Analysis,
} from "../interface/stock";
import {StockSignalsEnum, StockStatsIntervalEnum} from "../dict";
import {MathEx} from "../utils/math.ex";
// import {IPredictionAdmin} from "../interface";
import {MinMaxData, MinMaxStats, MinMaxStatsAtr} from "./min-max.data";
import {SupResLine} from "./sup-res-lines.data";
import {TaLibStockInput} from "./ta-lib.data";
import {HelperSignal} from "./signal/helper.signal";
import {PredictionSignal} from "./signal/prediction.signal";

export interface CumulativeStatsInput {
    stats: {
        stock: readonly IQuoteFull[];
        pred: readonly Analysis.Prediction[];
    }
    params: {
        // prevClose: number, // prev range close price
        // prevCloseThreshold: number, // a rate like 0.001
        signalSetup: Readonly<SignalSetup>, // not everything is supported, only gaps for a signal
    }
}

export interface CumulativeStatsLines {
    last5predsAvg: LinePoints,
    last15minAvg: LinePoints,
    signals4h: LinePoints,
    macd: LinePoints,
    vwap: LinePoints,
    last30minAvg: LinePoints,
    last3predsAvg: LinePoints,
    last1hAvg: LinePoints,

    last2hAvg: LinePoints,
    last3hAvg: LinePoints,

    last4hAvg: LinePoints,

    // predVals: LinePoints,
    ema6: LinePoints,
    ema6day: LinePoints,
    ema12: LinePoints,
    ema12day: LinePoints,
    ema26: LinePoints,
    ema26day: LinePoints,
    ema120: LinePoints,
    sma120: LinePoints,


    signalsLast3p: LinePoints,
    signalsLast15m: LinePoints,
    signalsMacdCross: LinePoints,
    signalsVwapPrice: LinePoints,
    signalsConsensus3Pred10m: LinePoints,
    signalsConsensus4Pred10m: LinePoints,
    signalsConsensus5Pred10m: LinePoints,
    signalsEma6Price: LinePoints,
    signalsEma12Price: LinePoints,
    signalsEma26Price: LinePoints,
    // signalsEma26PriceRepeat: LinePoints,
    signalsEma120Price: LinePoints,
    signalsSma120Price: LinePoints,

    // min/max related
    signals2h: LinePoints,
    signals2hMinMax220pctStockPred: LinePoints,
    signals2hMinMax220pctPred: LinePoints,
    signals2hMinMax160pctStock: LinePoints,
    signals2hMinMax220pctStock: LinePoints,
    signals2hMinMax160pctStockInside: LinePoints,
    signals2hMinMax220pctStockInside: LinePoints,

    // min/max atr related
    [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: LinePoints,
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: LinePoints,
    [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: LinePoints,
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: LinePoints,
    [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: LinePoints,
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: LinePoints,
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: LinePoints,

    signalsLast2hAvgStock: LinePoints,

    // signalTrendLines: LinePoints,
    openLine: LinePoints, // range start point, 2 points to draw line
    prevCloseLine: LinePoints, // prev range close line, 2 points

    supResLines: SupResLine[],
}

export interface CumulativeStatsResult {
    signals: {
        [StockSignalsEnum.SIGNALS_CONSENSUS_3PRED_10M]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_CONSENSUS_4PRED_10M]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_CONSENSUS_5PRED_10M]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_MACD_CROSS]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA6_PRICE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA12_PRICE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA26_PRICE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA26_PRICE_REPEAT]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA120_PRICE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA6_CANDLE]: PointUpDown[],
        // [StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA12_CANDLE]: PointUpDown[],
        // [StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA26_CANDLE]: PointUpDown[],
        // [StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_EMA120_CANDLE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_SMA120_PRICE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_4H]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_VWAP_PRICE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_LAST_15MIN]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_LAST_3PRED]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK]: PointUpDown[],
        // [StockSignalsEnum.SIGNALS_OPEN_LINE_STOCK]: PointUpDown[],
        // [StockSignalsEnum.SIGNALS_PREV_ClOSE_LINE_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_LAST_2H_AVG_STOCK]: PointUpDown[],
    },
    signalSetupResults: (PointUpDown[])[],
    yMax: any,
    yMin: any,
    minMaxStats: MinMaxStats,
    minMaxStatsAtr: MinMaxStatsAtr,
    cumulStats: CumulativeStatsLines,
    // old: {
    //     // min/max 4h avg
    //     min2hAvg:    LinePoints,
    //     max2hAvg:    LinePoints,
    //     min2h60Avg:    LinePoints,
    //     max2h60Avg:    LinePoints,
    //     min2h220pctAvg:    LinePoints,
    //     max2h220pctAvg:    LinePoints, // 220%
    // }
}

interface EmaSignals {
    ema6: PointUpDown[],
    ema12: PointUpDown[],
    ema26: PointUpDown[],
    ema26Repeat: PointUpDown[],
    ema120: PointUpDown[],

    ema6Candle: PointUpDown[],
    ema12Candle: PointUpDown[],
    ema26Candle: PointUpDown[],
    ema120Candle: PointUpDown[],
    sma120: PointUpDown[],

    // ema6CandleDay: [],
    // ema12CandleDay: [],
    // ema26CandleDay: [],
}

export class CumulativeData {



    /**
     *
     * @param stats
     * @param stockLine
     * @param prevClose prev range close value, necessary for signal's line
     */
    public static calcCumulativeChartData(input: CumulativeStatsInput): CumulativeStatsResult {

        const stats = {
            stockStats: input.stats.stock,
            predStats: input.stats.pred,
        }; // as StatsData;
        const params = input.params;

        const emptyLine = ()=>CumulativeData.emptyLine();
        const cumulStats:CumulativeStatsLines = {
            // predVals: emptyLine(),
            last5predsAvg: emptyLine(),
            last3predsAvg: emptyLine(),

            signals4h: emptyLine(),

            signals2h: emptyLine(),
            signals2hMinMax220pctStockPred: emptyLine(),
            signals2hMinMax220pctPred: emptyLine(),
            signals2hMinMax160pctStock: emptyLine(),
            signals2hMinMax220pctStock: emptyLine(),
            signals2hMinMax160pctStockInside: emptyLine(),
            signals2hMinMax220pctStockInside: emptyLine(),

            [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: emptyLine(),
            [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: emptyLine(),
            [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: emptyLine(),
            [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: emptyLine(),
            [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: emptyLine(),
            [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: emptyLine(),
            [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: emptyLine(),

            signalsLast3p: emptyLine(),
            signalsLast15m: emptyLine(),
            signalsMacdCross: emptyLine(),
            signalsEma6Price: emptyLine(),
            signalsEma12Price: emptyLine(),
            signalsEma26Price: emptyLine(),
            signalsEma120Price: emptyLine(),
            signalsSma120Price: emptyLine(),
            signalsVwapPrice: emptyLine(),
            signalsConsensus3Pred10m: emptyLine(),
            signalsConsensus4Pred10m: emptyLine(),
            signalsConsensus5Pred10m: emptyLine(),
            signalsLast2hAvgStock: emptyLine(),

            // signalsSupRes1hStock: emptyLine(),
            last15minAvg: emptyLine(),
            last30minAvg: emptyLine(),
            last1hAvg: emptyLine(),
            last2hAvg: emptyLine(),
            last3hAvg: emptyLine(),
            last4hAvg: emptyLine(),

            macd: emptyLine(),
            ema6: emptyLine(),
            ema6day: emptyLine(),
            ema12: emptyLine(),
            ema12day: emptyLine(),
            ema26: emptyLine(),
            ema26day: emptyLine(),
            ema120: emptyLine(),
            sma120: emptyLine(),
            // https://www.barchart.com/education/technical-indicators/vwap
            vwap: emptyLine(),
            supResLines: [] as SupResLine[],
            // signalTrendLines: emptyLine(),
            openLine: emptyLine(),
            prevCloseLine: emptyLine(),
        };
        const isEmpty = stats.stockStats.length===0
        if (!isEmpty) {
            const first = stats.stockStats[0];
            const last = stats.stockStats[stats.stockStats.length - 1];
            const startTime = first.t;
            const lastTime = last.t;

            const addLine = (l:LinePoints, v:number) =>  {
                l.x.push(startTime);
                l.y.push(v);
                l.x.push(lastTime);
                l.y.push(v);
            }
            addLine(cumulStats.openLine, first.c);
            // addLine(cumulStats.prevCloseLine, params.prevClose);
        }



        let {
            yMin,
            yMax,
            // tslint:disable-next-line:prefer-const
            setUpResults,
            // tslint:disable-next-line:prefer-const
            minMaxCumMap,
            // tslint:disable-next-line:prefer-const
            signalsConsensus3Pred10m, signalsConsensus4Pred10m, signalsConsensus5Pred10m,
        } = this.calcPredLinesSignals(stats.predStats, params, cumulStats);


        const signalSupResStock = SignalsData.calcSupResStock(stats.stockStats);
        // addSignalToStats(signalSupResStock.signals, cumulStats.signalsSupRes1hStock);
        cumulStats.supResLines = signalSupResStock.lines;

        const stockSignalsRes = this.calcStockSignals({
            signalSetup: params.signalSetup,
            openPrice: cumulStats.openLine.y[0],
            last3predsAvg: cumulStats.last3predsAvg,
            supResLines: cumulStats.supResLines,
            setupResults: setUpResults,
        }, stats);
        const {
            minMaxStatsAtr,
            emaSignals,
            stockCalculatedLines,
            stockLine,
            vwapData
        } = stockSignalsRes;
        cumulStats.ema6 = stockCalculatedLines.ema6;
        cumulStats.ema12 = stockCalculatedLines.ema12;
        cumulStats.ema26 = stockCalculatedLines.ema26;
        cumulStats.ema120 = stockCalculatedLines.ema120;
        cumulStats.vwap = stockCalculatedLines.vwap; // vWap.line = lines.
        cumulStats.sma120 = stockCalculatedLines.sma120; // sma120: cumulStats.sma120,
        cumulStats.macd = stockCalculatedLines.macd;
        cumulStats.ema6day = stockCalculatedLines.ema6day;
        cumulStats.ema12day = stockCalculatedLines.ema12day;
        cumulStats.ema26day = stockCalculatedLines.ema26day;

        yMin = Math.min(stockSignalsRes.yMin, yMin||stockSignalsRes.yMin);
        yMax = Math.min(stockSignalsRes.yMax, yMax||stockSignalsRes.yMax);
        const minMaxStatsPred = MinMaxData.calcMinMax(minMaxCumMap);
        const addSignalToStats = (signals: PointUpDown[], p:LinePoints)=>{
            signals.forEach(s=>{
                p.x.push(s.x);
                p.y.push(s.y);
            });
        }
        // const toMinMaxLine = (min:LinePoints,max:LinePoints, rangeSecs: number, factor:number, points: MinMaxPointsMap)=>{
        //     return {min: min,max: max, rangeSecs: rangeSecs, factor: factor, points: points} as MinMaxLines;
        // };
        const signals4h = SignalsData.calcSignals(
                {x: cumulStats.last3predsAvg.x, y: cumulStats.last3predsAvg.y},
                stockLine,
                minMaxStatsPred.stats4h160pctAvg,
                // toMinMaxLine(minMaxStats.min4h160pctAvg, minMaxStats.max4h160pctAvg, 240 * 60,0.6, minMaxCumMap),
        );
        addSignalToStats(signals4h, cumulStats.signals4h);

        const signals2h = SignalsData.calcSignals(

                {x: cumulStats.last3predsAvg.x, y: cumulStats.last3predsAvg.y},
                stockLine,
                minMaxStatsPred.stats2h160pctAvg
                // toMinMaxLine(minMaxStats.min2h160pctAvg, minMaxStats.max2h160pctAvg, 120 * 60, 0.6, minMaxCumMap)
        );
        addSignalToStats(signals2h, cumulStats.signals2h);

        const signals2hMinMax220pctStock3Pred = SignalsData.calcSignals(
                {x: cumulStats.last3predsAvg.x, y: cumulStats.last3predsAvg.y},
                stockLine,
                minMaxStatsPred.stats2h220pctAvg
        );
        addSignalToStats(signals2hMinMax220pctStock3Pred, cumulStats.signals2hMinMax220pctStockPred);

        const signals2hMinMax220pctPred = SignalsData.calcSignalsPredUpDownMinMax(stats.predStats,minMaxStatsPred.stats2h220pctAvg);
        addSignalToStats(signals2hMinMax220pctPred, cumulStats.signals2hMinMax220pctPred);

        const signals2hMinMax220pctStock = SignalsData.calcSignalsStockUpDownMinMax(stockLine, minMaxStatsPred.stats2h220pctAvg);
        addSignalToStats(signals2hMinMax220pctStock, cumulStats.signals2hMinMax220pctStock);

        const signals2hMinMax160pctStock = SignalsData.calcSignalsStockUpDownMinMax(stockLine,minMaxStatsPred.stats2h160pctAvg);
        addSignalToStats(signals2hMinMax160pctStock, cumulStats.signals2hMinMax160pctStock);


        const signals2hMinMax160pctStockInside = SignalsData.calcSignalsStockUpDownMinMaxInside(stockLine,minMaxStatsPred.stats2h160pctAvg);
        addSignalToStats(signals2hMinMax160pctStockInside, cumulStats.signals2hMinMax160pctStockInside);

        const signals2hMinMax220pctStockInside = SignalsData.calcSignalsStockUpDownMinMaxInside(stockLine,minMaxStatsPred.stats2h220pctAvg);
        addSignalToStats(signals2hMinMax220pctStockInside, cumulStats.signals2hMinMax220pctStockInside);

        const signalsLast3pred = SignalsData.calcSignalUpDownStock(
                stockLine,[{x: cumulStats.last3predsAvg.x, y: cumulStats.last3predsAvg.y},],
        );
        addSignalToStats(signalsLast3pred, cumulStats.signalsLast3p);

        const signalsLast2hAvgStock = SignalsData.calcSignalCrossStock(
                stockLine,{x: cumulStats.last2hAvg.x, y: cumulStats.last2hAvg.y},
        );
        addSignalToStats(signalsLast2hAvgStock, cumulStats.signalsLast2hAvgStock);

        const signalsLast15m = SignalsData.calcSignalUpDownStock(
                stockLine,[{x: cumulStats.last15minAvg.x, y: cumulStats.last15minAvg.y},],
        );
        addSignalToStats(signalsLast15m, cumulStats.signalsLast15m);

        addSignalToStats(vwapData.signals, cumulStats.signalsVwapPrice);



        addSignalToStats(emaSignals.ema6, cumulStats.signalsEma6Price);
        addSignalToStats(emaSignals.ema12, cumulStats.signalsEma12Price);
        addSignalToStats(emaSignals.ema26, cumulStats.signalsEma26Price);
        // addSignalToStats(emaSignals.ema26Repeat, cumulStats.signalsEma26PriceRepeat);
        addSignalToStats(emaSignals.ema120, cumulStats.signalsEma120Price);
        addSignalToStats(emaSignals.sma120, cumulStats.signalsSma120Price);

        const signalsMacdCross: PointUpDown[] = [];
        const macDY = cumulStats.macd.y;
        for (let i = 25; i<macDY.length; i++) {
            const prev = macDY[i-1];
            const curr = macDY[i];
            const x = cumulStats.macd.x[i];
            const y = stockLine.y[i];
            if (prev<0 && curr>=0) {
                signalsMacdCross.push({x: x, y: y, isSellSignal: false});
            } else if (prev>0 && curr<=0) {
                signalsMacdCross.push({x: x, y: y, isSellSignal: true});
            }
        }
        addSignalToStats(signalsMacdCross, cumulStats.signalsMacdCross);

        addSignalToStats(signalsConsensus3Pred10m, cumulStats.signalsConsensus3Pred10m);
        addSignalToStats(signalsConsensus4Pred10m, cumulStats.signalsConsensus4Pred10m);
        addSignalToStats(signalsConsensus5Pred10m, cumulStats.signalsConsensus5Pred10m);

        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]);
        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]);
        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]);
        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]);
        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]);
        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]);
        addSignalToStats(minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED], cumulStats[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]);

        return {
            cumulStats: cumulStats,
            minMaxStats: minMaxStatsPred,
            minMaxStatsAtr: minMaxStatsAtr,
            signals: {
                [StockSignalsEnum.SIGNALS_4H]: signals4h,
                [StockSignalsEnum.SIGNALS_LAST_3PRED]: signalsLast3pred,
                [StockSignalsEnum.SIGNALS_LAST_15MIN]: signalsLast15m,
                [StockSignalsEnum.SIGNALS_MACD_CROSS]: signalsMacdCross,
                [StockSignalsEnum.SIGNALS_EMA6_PRICE]: emaSignals.ema6,
                [StockSignalsEnum.SIGNALS_EMA12_PRICE]: emaSignals.ema12,
                [StockSignalsEnum.SIGNALS_EMA26_PRICE]: emaSignals.ema26,
                [StockSignalsEnum.SIGNALS_EMA26_PRICE_REPEAT]: emaSignals.ema26Repeat,
                [StockSignalsEnum.SIGNALS_EMA120_PRICE]: emaSignals.ema120,
                [StockSignalsEnum.SIGNALS_EMA6_CANDLE]: emaSignals.ema6Candle,
                // [StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY]: emaSignals.ema6CandleDay,
                [StockSignalsEnum.SIGNALS_EMA12_CANDLE]: emaSignals.ema12Candle,
                // [StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY]: emaSignals.ema12CandleDay,
                [StockSignalsEnum.SIGNALS_EMA26_CANDLE]: emaSignals.ema26Candle,
                // [StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY]: emaSignals.ema26CandleDay,
                [StockSignalsEnum.SIGNALS_EMA120_CANDLE]: emaSignals.ema120Candle,
                [StockSignalsEnum.SIGNALS_SMA120_PRICE]: emaSignals.sma120,
                [StockSignalsEnum.SIGNALS_VWAP_PRICE]: vwapData.signals,
                [StockSignalsEnum.SIGNALS_CONSENSUS_3PRED_10M]: signalsConsensus3Pred10m,
                [StockSignalsEnum.SIGNALS_CONSENSUS_4PRED_10M]: signalsConsensus4Pred10m,
                [StockSignalsEnum.SIGNALS_CONSENSUS_5PRED_10M]: signalsConsensus5Pred10m,

                [StockSignalsEnum.SIGNALS_2H]: signals2h,
                [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED]: signals2hMinMax220pctStock3Pred,
                [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_PRED]: signals2hMinMax220pctPred,
                [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK]: signals2hMinMax220pctStock,
                [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK]: signals2hMinMax160pctStock,
                [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE]: signals2hMinMax220pctStockInside,
                [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE]: signals2hMinMax160pctStockInside,

                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED],
                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK],
                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: minMaxStatsAtr.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE],


                [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK]: signalSupResStock.signals,
                // [StockSignalsEnum.SIGNALS_OPEN_LINE_STOCK]: openLineSignals,
                // [StockSignalsEnum.SIGNALS_PREV_ClOSE_LINE_STOCK]: prevCloseLineSignals,
                [StockSignalsEnum.SIGNALS_LAST_2H_AVG_STOCK]: signalsLast2hAvgStock,
            },
            signalSetupResults: setUpResults,
            yMin, yMax,

            // old: oldMinMax,
        };
    }

    public static calcStockSignals(
            params: {
                signalSetup: Readonly<SignalSetup>,
                openPrice: number,
                last3predsAvg: LinePoints,
                // vwap: LinePoints,
                // sma120: LinePoints,
                supResLines: SupResLine[],
                setupResults: (PointUpDown[])[],
                // ema6: LinePoints,
                // ema12: LinePoints,
                // ema26: LinePoints,
                // ema120: LinePoints,
                // prevDayPrices?: IQuoteFull[], // yesterday - last
            },
            stats: { stockStats: readonly IQuoteFull[]; predStats: readonly Analysis.Prediction[] },
    ) {

        const emaSignals: EmaSignals  = {
            ema6: [],
            ema12: [],
            ema26: [],
            ema26Repeat: [],
            ema120: [],

            ema6Candle: [],
            ema12Candle: [],
            ema26Candle: [],
            ema120Candle: [],
            sma120: [],

            // ema6CandleDay: [],
            // ema12CandleDay: [],
            // ema26CandleDay: [],
        }
        // @TODO: refactor
        let prices: IQuoteFull[] = [];
        params.signalSetup?.signals?.forEach((v)=>{
            const opts = v?.options as SignalOptionsFull;
            if (opts?.prevDaysPrices && opts?.prevDaysPrices?.length>0) {
                prices = opts.prevDaysPrices;
            }
        });
        const linesGetCalc = this.calcStockLines(prices);
        const linesData = linesGetCalc.data();
        const stockCalculatedLines = linesData.lines;
        stockCalculatedLines.supRes = params.supResLines;
        const stockLine: LinePoints = this.emptyLine();

        // copy object
        const signalsSetup = JSON.parse(JSON.stringify(params.signalSetup)) as SignalSetup;

        const minMaxStatsAtr = MinMaxData.emptyMinMaxAtr();

        const talibInput5m: TaLibStockInput = {
            close: [],
            open: [],
            high: [],
            low: []
        }
        const addToTaLibMinute = (q: IQuote, tas: TaLibStockInput, pos:number) => {
            const lastPos = tas.open.length-1;
            if (lastPos<pos) {
                tas.open.push(q.o);
                tas.close.push(q.c);
                tas.high.push(q.h);
                tas.low.push(q.l);
            } else {
                tas.open[lastPos] = q.o;
                tas.close[lastPos] = q.c;
                tas.high[lastPos] = q.h;
                tas.low[lastPos] = q.l;
            }
        }
        const talibInput: TaLibStockInput = {
            close: [],
            open: [],
            high: [],
            low: []
        }

        const stats5m: IQuoteFull[] = [];

        for (const ss of signalsSetup?.signals||[]) {
            ss.options = ss.options || {};
            const opts = ss.options as SignalOptionsFull;
            // add reference to object which will be populated on fly
            opts.minMax2hVd30m = minMaxStatsAtr;
            // opts.prevDayClosePrice = params.prevClosePrice;
            opts.openPrice = params.openPrice;
            opts.lines = stockCalculatedLines;
            // {
            //     vwap: stockCalculatedLines.vwap, // params.vwap,
            //     sma120: stockCalculatedLines.sma120, // params.sma120,
            //     supRes: params.supResLines,
            // }
        }

        let yMin:number = null as any;
        let yMax:number = null as any;
        if (stats.stockStats.length > 0) {
            let prevDPoint: IQuoteFull = null as any;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < stats.stockStats.length; i++) {
                const sCurr = stats.stockStats[i];
                stockLine.x.push(sCurr.t);
                stockLine.y.push(sCurr.c);

                // calc lines for current minute firstly
                linesGetCalc.calc(i, stats.stockStats);
                // this.calcStockLines(i, stats.stockStats, stockCalculatedLines);
                // vwapGetCal.calc(stats.stockStats[i]);

                this.calcEmaSignals(i, stats.stockStats, emaSignals, stockCalculatedLines);

                // new 5m
                const isNew5m = sCurr.t % 300 === 0;
                const m5 = Math.floor(sCurr.t / 300) * 300;
                if (isNew5m) {
                    stats5m.push(Object.assign({}, sCurr));
                } else {
                    // add to existing or it's first minute for 5m range
                    const last5min = stats5m?.[stats5m.length-1];
                    if (last5min?.t === m5) {
                        last5min.l = Math.min(last5min.l, sCurr.l);
                        last5min.c = sCurr.c;
                        last5min.h = Math.max(last5min.h, sCurr.h);
                        last5min.v+=sCurr.v;
                    } else {
                        stats5m.push(Object.assign({}, sCurr));
                    }
                }
                // after minutes are ready, add finally to taLibInput
                addToTaLibMinute(sCurr, talibInput, i);

                addToTaLibMinute(stats5m[stats5m.length - 1], talibInput5m, stats5m.length - 1);

                MinMaxData.calcMinMaxAtr(minMaxStatsAtr, i, stats.stockStats, params.last3predsAvg, stats.predStats);

                if (prevDPoint) {
                    // const sigPrev = calcSignal(sCurr, prevDPoint, prevClosePrice);
                    // if (sigPrev) {
                    //     prevCloseLineSignals.push(sigPrev);
                    // }

                    // const sigOpen = HelperSignal.calcSignal(sCurr, prevDPoint, openPrice);
                    // if (sigOpen) {
                    //     openLineSignals.push(sigOpen);
                    // }
                    SignalsData.handleStockSetupResults({
                        [StockStatsIntervalEnum.MIN_1]: {
                            currPos: i,
                            stock: stats.stockStats,
                            taStats: talibInput,
                        },
                        [StockStatsIntervalEnum.MIN_5]: {
                            currPos: stats5m.length - 1,
                            stock: stats5m,
                            taStats: talibInput5m,
                        }
                    }, sCurr.t, signalsSetup, params.setupResults);

                }
                yMin = Math.min(yMin==null ? sCurr.l : yMin, sCurr.l);
                yMax = Math.min(yMax==null ? sCurr.h : yMax, sCurr.h);
                prevDPoint = sCurr;
            }
        }
        return {
            // openLineSignals,
            // prevCloseLineSignals,
            emaSignals,
            minMaxStatsAtr,
            talibInput5m,
            stats5m,
            yMin,
            yMax,
            stockCalculatedLines,
            stockLine,
            vwapData: linesData.vwapData,
        };
    }

    public static calcVwap() {
        let volumeSum = 0;
        let priceVolSum = 0;
        // const line: Point[] = [];
        const d = {
            signals: [] as PointUpDown[],
            line: {x:[], y:[]} as LinePoints,
            min: null as any as number,
            max: null as any as number,
        };
        let prevDist:number = null as any;
        let prevTime:number = null as any;
        const calc = (q:IQuoteFull)=>{

            const close = q.c;
            const volume = q.v;
            const time = q.t;
            const price = (q.c+q.h+q.l)/3;
            // @TODO: it can be removed, because cumulative supports only 1d
            // reset calc, it can be multi-day calc
            if (prevTime!==null && q.t-prevTime>3600) {
                volumeSum = 0;
                priceVolSum = 0;
                prevDist = null as any;
                prevTime = null as any;
            }


            volumeSum+=volume;
            priceVolSum += price*volume;
            const vwapPrice = MathEx.round(priceVolSum/volumeSum, 4);

            d.min = d.min !== null ? Math.min(d.min, vwapPrice) : vwapPrice;
            d.max = d.max !== null ? Math.max(d.max, vwapPrice) : vwapPrice;
            d.line.x.push(time);
            d.line.y.push(vwapPrice);
            const newDist = vwapPrice-close;
            if (prevDist!==null) {
                if (prevDist<0 && newDist>=0) {
                    // signal to buy?
                    d.signals.push({x: time,y: vwapPrice,isSellSignal: true,});
                } if (prevDist>=0 && newDist<0) {
                    // signal to sell?
                    d.signals.push({x: time,y: vwapPrice,isSellSignal: false});
                }
            }
            prevDist = vwapPrice-close;
            prevTime = time;
        }
        return {
            getData: () => d,
            calc: calc,
        }
    }

    public static calcStockLines(prevDaysPrices:IQuoteFull[]) {
        const vwapGetCal = this.calcVwap();
        const lines: StockCalculatedLines = {
            macd: this.emptyLine(),
            ema6: this.emptyLine(),
            ema12: this.emptyLine(),
            ema26: this.emptyLine(),
            ema120: this.emptyLine(),
            sma120: this.emptyLine(),

            ema26day: this.emptyLine(),
            ema12day: this.emptyLine(),
            ema6day: this.emptyLine(),

            vwap: vwapGetCal.getData().line,
            supRes: [],
        }
        // start from first only
        // if (i===0) {
        //     return;
        // }
        // MACD=12-Period EMA − 26-Period EMA
        // EMA [today] = (Price [today] x K) + (EMA [yesterday] x (1 – K))
        //
        // Where:
        //
        //   K = 2 ÷(N + 1)
        // N = the length of the EMA
        // Price [today] = the current closing price
        // EMA [yesterday] = the previous EMA value
        // EMA [today] = the current EMA value
        const k120 = 2/(120-1);
        const k26 = 2/(26-1);
        const k12 = 2/(12-1);
        const k6 = 2/(6-1);

        const emaCalc = (k: number, today:number, prev:number)=>{
            if (!prev) {
                return today;
            }
            return MathEx.round(today * k + prev * (1 - k), 6);
        }

        let ema6dPreCalc = null as any as number;
        let ema12dPreCalc = null as any as number;
        let ema26dPreCalc = null as any as number;

        let count = 0;
        const len = prevDaysPrices.length;
        // precalculate ema for previous days, basically have yesterday's close EMA
        // it's use for today's calc for each minute
        for (let i=Math.max(len-1-25, 0); i<len;i++) {
            count++;
            const price = prevDaysPrices[i].c;
            if (i>=len-5 && len>=5) {
                // console.debug(i);
                ema6dPreCalc = emaCalc(k6, price, ema6dPreCalc);
            }
            if (i>=len-11 && len>=11) {
                ema12dPreCalc = emaCalc(k12, price, ema12dPreCalc);
            }
            if (i>=len-25 && len>=25) {
                ema26dPreCalc = emaCalc(k26, price, ema26dPreCalc);
            }
        }


        // use first closePrice to calc initial first point
        // const first = stockLine.y[0];
        // let prev:StockCalculatedLines = {
        //     time: stockLine.x[0],
        //     MACD: 0,
        //     EMA6: first,
        //     EMA12: first,
        //     EMA26: first,
        //     EMA120: first,
        //     SMA120: first,
        // };
        // lines.push(prev);
        //
        // for (let i=1; i<stockLine.x.length; i++) {
        let startIndex = null as any as number;
        let endIndex = null as any as number;
        const smaSize = 120;
        let sma120Sum = 0;
        let sma120Count = 0;
        const calc = (i: number, stockStats: readonly IQuoteFull[])=> {
            if (startIndex===null) {
                startIndex = i;
                endIndex = i;
            }
            const currQ = stockStats[i];
            const isFirst = i === 0;
            const time = currQ.t;
            const priceToday = currQ.c;
            // const ema6 = isFirst ? priceToday : priceToday * k6 + lines.ema6.y[i - 1] * (1 - k6);
            const ema6 = emaCalc(k6, priceToday, lines.ema6.y?.[i - 1]);
            // const ema12 = isFirst ? priceToday : priceToday * k12 + lines.ema12.y[i - 1] * (1 - k12);
            const ema12 = emaCalc(k12, priceToday, lines.ema12.y?.[i - 1]);
            // const ema26 = isFirst ? priceToday : priceToday * k26 + lines.ema26.y[i - 1] * (1 - k26);
            const ema26 = emaCalc(k26, priceToday, lines.ema26.y?.[i - 1])
            // const ema120 = isFirst ? priceToday : priceToday * k120 + lines.ema120.y[i - 1] * (1 - k120);
            const ema120 = emaCalc(k120, priceToday, lines.ema120.y?.[i - 1])
            const macd = isFirst ? 0 : MathEx.round(ema12 - ema26, 6);
            //
            // let sma120 = 0;
            // let sma120count = 0;
            sma120Sum+=priceToday;
            sma120Count+=1;
            endIndex = i;
            if (sma120Count>smaSize) {
                sma120Sum-=stockStats[startIndex].c;
                sma120Count-=1;
                startIndex++;
            }

            // const until = time - 7200;
            // for (let y = i; y >= 0; y--) {
            //     const p = stockStats[y];
            //     const yClose = p.c;
            //     if (sma120count>=smaSize) {
            //         break;
            //     }
            //     sma120 += yClose;
            //     sma120count++;
            // }

            const addPoint = (l: LinePoints, y: number) => {
                l.y.push(y);
                l.x.push(time);
            }
            vwapGetCal.calc(currQ);
            addPoint(lines.macd, macd);
            addPoint(lines.ema6, ema6);
            addPoint(lines.ema12, ema12);
            addPoint(lines.ema26, ema26);
            addPoint(lines.ema120, ema120);
            addPoint(lines.sma120, MathEx.round(sma120Sum / sma120Count, 4));

            if (ema6dPreCalc) {
                addPoint(lines.ema6day, emaCalc(k6, priceToday, ema6dPreCalc));
            }
            if (ema12dPreCalc) {
                addPoint(lines.ema12day, emaCalc(k12, priceToday, ema12dPreCalc));
            }
            if (ema26dPreCalc) {
                addPoint(lines.ema26day, emaCalc(k26, priceToday, ema26dPreCalc));
            }

            // const macdToday:StockCalculatedLines = {
            //     time: time,
            //     MACD: ema12 - ema26,
            //     EMA6: ema6,
            //     EMA12: ema12,
            //     EMA26: ema26,
            //     EMA120: ema120,
            //     // SMA120: MathEx.round(sma120/sma120count, 4),
            // };
            // lines.push(macdToday);
            // prev = macdToday;
            // }
            // return lines;
        }
        return {
            data:()=>{return{
                lines: lines,
                vwapData: vwapGetCal.getData(),
            }},
            calc: calc,
        }
    }

    /**
     * 1 - UP, -1 - DOWN
     * @param predictedValue
     * @param stockValueAtPredMadeTime
     */
    public static direction(predictedValue:number, stockValueAtPredMadeTime:number) {
        return HelperSignal.direction(predictedValue, stockValueAtPredMadeTime);
    }

    public static emptyLine() {
        return { x: [] as number[], y: [] as number[]} as LinePoints;
    }

    public static calcEmaSignals(currPos:Readonly<number>, stockLine: Readonly<IQuoteFull[]>, sgs: EmaSignals, emaLines: StockCalculatedLines) {
        // const stockY = stockLine.y;

        const ema6 = emaLines.ema6;
        // const ema6d = emaLines.ema6day;
        const ema12 = emaLines.ema12;
        // const ema12d = emaLines.ema12day;
        const ema26 = emaLines.ema26;
        // const ema26d = emaLines.ema26day;
        const ema120 = emaLines.ema120;
        const sma120 = emaLines.sma120;

        const calcSignalStock = (ema: LinePoints, i: number, signals: PointUpDown[], repeat=false) => {
            const time = stockLine[i].t;
            const prevEma = ema.y?.[i-1];
            if (!prevEma) {
                return;
            }
            const prevStock = stockLine[i-1].c;
            const currEma = ema.y[i];
            const currStock = stockLine[i].c;
            // console.debug({prevEma, prevStock, currEma, currStock});
            if (prevEma<prevStock && (currEma>=currStock || repeat)) {
                signals.push({x: time, y: currStock, isSellSignal: true});
            } else if (prevEma>prevStock && (currEma<=currStock || repeat)) {
                signals.push({x: time, y: currStock, isSellSignal: false});
            }
        }

        const calcSignalCandle = (ema: LinePoints, i: number, signals: PointUpDown[]) => {
            const time = stockLine[i].t;
            const prevEma = ema.y[i-1];
            const currEma = ema.y[i];

            const prevStock = stockLine[i-1];
            const currStock = stockLine[i];


            // console.debug({prevEma, prevStock, currEma, currStock});
            if (prevEma<prevStock.h && currEma>=currStock.h) {
                signals.push({x: time, y: currStock.h, isSellSignal: true});
            } else if (prevEma>prevStock.l && currEma<=currStock.l) {
                signals.push({x: time, y: currStock.l, isSellSignal: false});
            }
        }

        // for (let i = 5; i<stockLine.length; i++) {
        if (currPos>=5) {
            calcSignalStock(ema6, currPos, sgs.ema6);
            calcSignalCandle(ema6, currPos, sgs.ema6Candle);
        }
        if (currPos>=11) {
            calcSignalStock(ema12, currPos, sgs.ema12);
            calcSignalCandle(ema12, currPos, sgs.ema12Candle);
        }
        if (currPos>=25) {
            calcSignalStock(ema26, currPos, sgs.ema26);
            calcSignalStock(ema26, currPos, sgs.ema26Repeat, true);
            calcSignalCandle(ema26, currPos, sgs.ema26Candle);
        }
        if (currPos>=25) { // if (i>=120) { // @TODO: 120 is too long to wait
            calcSignalStock(ema120, currPos, sgs.ema120);
            calcSignalCandle(ema120, currPos, sgs.ema120Candle);
        }
        calcSignalStock(sma120, currPos, sgs.sma120);

        // if (currPos>0) {
        //     // emaNd has line for today, but the line is calculated using previous days too
        //     if (ema6d?.y.length) {
        //         calcSignalCandle(ema6d, currPos, sgs.ema6CandleDay);
        //     }
        //     if (ema12d?.y.length) {
        //         calcSignalCandle(ema12d, currPos, sgs.ema12CandleDay);
        //     }
        //     if (ema26d?.y.length) {
        //         calcSignalCandle(ema26d, currPos, sgs.ema26CandleDay);
        //     }
        // }

    }

    public static calcPredLinesSignals(predStats: Readonly<Analysis.Prediction[]>,
                                        params: { signalSetup: Readonly<SignalSetup> },
                                        cumulStats: CumulativeStatsLines
    ) {
        let yMin = null;
        let yMax = null;
        const items = predStats;
        const getValAvg = (pos:number)=>items[pos].value;

        const calcAvg = (st: (pos:number)=>number, startPos: number, condUntil: (pos: number) => boolean) => {
            let sum = 0;
            let count = 0;
            for (let y = startPos; y >= 0 && condUntil(y); y--) {
                count++;
                sum += st(y);
            }
            return sum / count;
        }

        const minMaxCumMap = MinMaxData.emptyMap();
        const setUpResults: (PointUpDown[])[] = Array.from((params.signalSetup?.signals || []).map((v) => []));

        const signalsConsensus3Pred10m: PointUpDown[] = [];
        const signalsConsensus4Pred10m: PointUpDown[] = [];
        const signalsConsensus5Pred10m: PointUpDown[] = [];
        const mp = new Map([
            [3, signalsConsensus3Pred10m],
            [4, signalsConsensus4Pred10m],
            [5, signalsConsensus5Pred10m]
        ]);
        for (let i = 0; i < items.length; i++) {
            const p = items[i];
            // if (p.valueTime >= options.startTime && p.valueTime <= options.endTime) {
            const val = p.value;
            const time = p.valueTime;
            const deviation = p.valueDeviation;
            const deviationHalf = deviation / 2;
            mp.forEach((v,k)=>{
                const res = PredictionSignal.calcSignalsConsensusNPredMmin({
                    predictions: items,
                    options: {durationSecs: 600, minCount: k},
                    currPos: i
                });
                if (res) {
                    v.push({
                        x: time,
                        y: res.price,
                        isSellSignal: res.dir===StockDirectionType.DOWN,
                    })
                }
            });

            SignalsData.handlePredSetupResults(items, i, params.signalSetup, setUpResults);

            // cumulStats.predVals.x.push(time);
            // cumulStats.predVals.y.push(val);

            cumulStats.last5predsAvg.y.push(calcAvg(getValAvg, i, (y) => y >= i - 4));
            cumulStats.last5predsAvg.x.push(time);

            cumulStats.last3predsAvg.y.push(calcAvg(getValAvg, i, (y) => y >= i - 2));
            cumulStats.last3predsAvg.x.push(time);


            cumulStats.last15minAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 15 * 60));
            cumulStats.last15minAvg.x.push(time);

            cumulStats.last30minAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 30 * 60));
            cumulStats.last30minAvg.x.push(time);

            cumulStats.last1hAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 60 * 60));
            cumulStats.last1hAvg.x.push(time);

            cumulStats.last2hAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 120 * 60));
            cumulStats.last2hAvg.x.push(time);

            cumulStats.last3hAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 180 * 60));
            cumulStats.last3hAvg.x.push(time);

            cumulStats.last4hAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 240 * 60));
            cumulStats.last4hAvg.x.push(time);

            cumulStats.last4hAvg.y.push(calcAvg(getValAvg, i, (y) => items[y].valueTime >= time - 240 * 60));
            cumulStats.last4hAvg.x.push(time);

            MinMaxData.addValToMinMaxMap(time, minMaxCumMap, val, deviationHalf);
            yMin = Math.min(yMin || val, val);
            yMax = Math.max(yMax || val, val);

        }
        return {yMin,yMax, setUpResults, minMaxCumMap, signalsConsensus3Pred10m, signalsConsensus4Pred10m, signalsConsensus5Pred10m};
    }
}
