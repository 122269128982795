import { StockSignalsEnum } from "predictagram-lib";
import { checkboxNames, stockSignalsColorMap } from "./helper";

export const ColoredSignalName: React.FunctionComponent<{ color: string, label: string}> = ({ color, label }) => {
  return (
    <div className="d-flex justify-content-start align-items-center flex-row gap-1">
      <div style={{ width: "10px", height: "10px", backgroundColor: color }}></div>
      <div/* key={`${key}`}*/>{label}</div>
    </div>
  );
}

export const coloredSignalNameMapper = (s: StockSignalsEnum, i: number, sgColor?:string) => {
  // const color = stockSignalsColorMap.get(s) || ['#ffffff', '#ffffff'];
  const defColor = 'rgba(255, 255, 255, 1)';
  const name = checkboxNames.get(s) || s;
  return <ColoredSignalName color={sgColor||defColor} label={name} key={`signal-${i}`} />
}

// export const toggleSignalNameMap = (s: StockSignalsEnum, i: number) => {
//   return <div className="d-flex gap-2">
//     <EyeToggle onDisable={() => { }} onEnable={() => { }} value={true} />
//     {coloredSignalNameMapper(s, i)}
//   </div>
// }

// export const toggleSignalName = (s: StockSignalsEnum, i: number, onEnable: ()=>void, onDisable: ()=>void) => {
//   return <div className="d-flex gap-2" key={`toggle-${i}`} >
//     <EyeToggle onDisable={() => { }} onEnable={onEnable} value={true} />
//     {coloredSignalNameMapper(s, i)}
//   </div>
// }
