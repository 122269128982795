import {StockStatsIntervalEnum} from "./stock-stats-interval.enum";

export enum StockSignalsEnum {
    SIGNALS_4H              = 'signals4h', // ['signals4h', 'Signal 4h'],
    SIGNALS_2H              = 'signals2h', // ['signals2h', 'Signal 2h'],
    SIGNALS_LAST_3PRED      = 'signalsLast3pred', // ['signalsLast3pred', 'Signal Last3PredAvg'],
    SIGNALS_LAST_15MIN      = 'signalsLast15m', //    ['signalsLast15m', 'Signal Last15mAvg'],
    SIGNALS_MACD_CROSS      = 'signalsMacdCross', //    ['signalsMacdCross', 'Signals Macd Cross'],
    SIGNALS_EMA6_PRICE      = 'signalsEma6Price',
    SIGNALS_EMA6_CANDLE_DAY             = 'ema6CdlDay',
    SIGNALS_EMA12_PRICE     = 'signalsEma12Price',
    SIGNALS_EMA12_CANDLE_DAY            = 'ema12CdlDay',
    SIGNALS_EMA26_PRICE     = 'signalsEma26Price',
    SIGNALS_EMA26_CANDLE_DAY            = 'ema26CdlDay',
    SIGNALS_EMA26_PRICE_REPEAT     = 'signalsEma26PriceRepeat',
    SIGNALS_EMA120_PRICE     = 'signalsEma120Price',
    SIGNALS_EMA6_CANDLE      = 'signalsEma6Candle',
    SIGNALS_EMA12_CANDLE     = 'signalsEma12Candle',
    SIGNALS_EMA26_CANDLE     = 'signalsEma26Candle',
    SIGNALS_EMA120_CANDLE     = 'signalsEma120Candle',

    SIGNALS_SMA120_PRICE     = 'signalsSma120Price',
    SIGNALS_VWAP_PRICE      = 'signalsVwapPrice',
    SIGNALS_CONSENSUS_3PRED_10M = 'signalsConsensus3Pred10m',
    SIGNALS_CONSENSUS_4PRED_10M = 'signalsConsensus4Pred10m',
    SIGNALS_CONSENSUS_5PRED_10M = 'signalsConsensus5Pred10m',

    PREDS_CONSENSUS                 = 'predsConsensus',

    // pred outside min/max line
    SIGNALS_2H_MIN_MAX_220PCT_PRED    = 'signals2hMinMax220pctPred',
    SIGNALS_2H_MIN_MAX_160PCT_STOCK   = 'signals2hMinMax160pctStock',
    SIGNALS_2H_MIN_MAX_220PCT_STOCK   = 'signals2hMinMax220pctStock',
    SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE   = 'signals2hMinMax160pctStockInside',
    SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE   = 'signals2hMinMax220pctStockInside',
    SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED = 'signals2hMinMax220pctStock3Pred',

    SIGNALS_2H_VD30M_160PCT_STOCK_3PRED         = 'signals2hVd30m160pctStock3Pred',
    SIGNALS_2H_VD30M_220PCT_STOCK_3PRED         = 'signals2hVd30m220pctStock3Pred',
    SIGNALS_2H_VD30M_400PCT_STOCK_3PRED         = 'signals2hVd30m400pctStock3Pred',
    SIGNALS_2H_VD30M_160PCT_STOCK               = 'signals2hVd30m160pctStock',
    SIGNALS_2H_VD30M_220PCT_STOCK               = 'signals2hVd30m220pctStock',
    SIGNALS_2H_VD30M_400PCT_STOCK               = 'signals2hVd30m400pctStock',
    SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE        = 'signals2hVd30m160pctStockInside',
    SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE        = 'signals2hVd30m220pctStockInside',
    SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE        = 'signals2hVd30m400pctStockInside',
    SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE        = 'signals2hVd30m220pctPredOutside',

    SIGNALS_SUP_RES_1H_STOCK                = 'signalsSupRes1HStock',

    // SIGNALS_TREND_LINES_STOCK_OUTSIDE                = 'signalsTrendLinesOutside',
    SIGNALS_OPEN_LINE_STOCK                          = 'signalsOpenLine',
    SIGNALS_PREV_ClOSE_LINE_STOCK                    = 'signalsCloseLine',

    SIGNALS_LAST_2H_AVG_STOCK                       = 'signalsLast2hAvgStock',

    SIGNALS_STOCK_GAP                               = 'signalsStockGap',

    SIGNALS_PRICE_PREV_DAY_HIGH_LOW               = 'M1_PricePrevDayHighLow',
    SIGNALS_PRICE_OPEN_RANGE_HIGH_LOW             = 'M1_PriceOpenRangeHighLow',
    SIGNALS_PRICE_PREV_RANGE_HIGH_LOW             = 'M1_PricePrevRangeHighLow',

    // https://github.com/TA-Lib/ta-lib-python/tree/master?tab=readme-ov-file#pattern-recognition
    // TALIB_CDL3LINESTRIKE                            = 'TALIB_CDL3LINESTRIKE',
    TALIB_CDL2CROWS                 = 'TALIB_CDL2CROWS',    //    Two Crows
    TALIB_CDL3BLACKCROWS       = 'TALIB_CDL3BLACKCROWS', // Three Black Crows
    TALIB_CDL3INSIDE       = 'TALIB_CDL3INSIDE', //  Three Inside Up/Down
    TALIB_CDL3LINESTRIKE       = 'TALIB_CDL3LINESTRIKE', //   Three-Line Strike
    TALIB_CDL3OUTSIDE       = 'TALIB_CDL3OUTSIDE', //          Three Outside Up/Down
    TALIB_CDL3STARSINSOUTH       = 'TALIB_CDL3STARSINSOUTH', //     Three Stars In The South
    TALIB_CDL3WHITESOLDIERS       = 'TALIB_CDL3WHITESOLDIERS', //    Three Advancing White Soldiers
    TALIB_CDLABANDONEDBABY       = 'TALIB_CDLABANDONEDBABY', //     Abandoned Baby
    TALIB_CDLADVANCEBLOCK       = 'TALIB_CDLADVANCEBLOCK', //      Advance Block
    TALIB_CDLBELTHOLD       = 'TALIB_CDLBELTHOLD', //          Belt-hold
    TALIB_CDLBREAKAWAY       = 'TALIB_CDLBREAKAWAY', //         Breakaway
    TALIB_CDLCLOSINGMARUBOZU       = 'TALIB_CDLCLOSINGMARUBOZU', //   Closing Marubozu
    TALIB_CDLCONCEALBABYSWALL       = 'TALIB_CDLCONCEALBABYSWALL', //  Concealing Baby Swallow
    TALIB_CDLCOUNTERATTACK       = 'TALIB_CDLCOUNTERATTACK', //     Counterattack
    TALIB_CDLDARKCLOUDCOVER       = 'TALIB_CDLDARKCLOUDCOVER', //    Dark Cloud Cover
    TALIB_CDLDOJI       = 'TALIB_CDLDOJI', //              Doji
    TALIB_CDLDOJISTAR       = 'TALIB_CDLDOJISTAR', //          Doji Star
    TALIB_CDLDRAGONFLYDOJI       = 'TALIB_CDLDRAGONFLYDOJI', //     Dragonfly Doji
    TALIB_CDLENGULFING       = 'TALIB_CDLENGULFING', //         Engulfing Pattern
    TALIB_CDLEVENINGDOJISTAR       = 'TALIB_CDLEVENINGDOJISTAR', //   Evening Doji Star
    TALIB_CDLEVENINGSTAR       = 'TALIB_CDLEVENINGSTAR', //       Evening Star
    TALIB_CDLGAPSIDESIDEWHITE       = 'TALIB_CDLGAPSIDESIDEWHITE', //  Up/Down-gap side-by-side white lines
    TALIB_CDLGRAVESTONEDOJI       = 'TALIB_CDLGRAVESTONEDOJI', //    Gravestone Doji
    TALIB_CDLHAMMER       = 'TALIB_CDLHAMMER', //            Hammer
    TALIB_CDLHANGINGMAN       = 'TALIB_CDLHANGINGMAN', //        Hanging Man
    TALIB_CDLHARAMI       = 'TALIB_CDLHARAMI', //            Harami Pattern
    TALIB_CDLHARAMICROSS       = 'TALIB_CDLHARAMICROSS', //       Harami Cross Pattern
    TALIB_CDLHIGHWAVE       = 'TALIB_CDLHIGHWAVE', //          High-Wave Candle
    TALIB_CDLHIKKAKE       = 'TALIB_CDLHIKKAKE', //           Hikkake Pattern
    TALIB_CDLHIKKAKEMOD       = 'TALIB_CDLHIKKAKEMOD', //        Modified Hikkake Pattern
    TALIB_CDLHOMINGPIGEON       = 'TALIB_CDLHOMINGPIGEON', //      Homing Pigeon
    TALIB_CDLIDENTICAL3CROWS       = 'TALIB_CDLIDENTICAL3CROWS', //   Identical Three Crows
    TALIB_CDLINNECK       = 'TALIB_CDLINNECK', //            In-Neck Pattern
    TALIB_CDLINVERTEDHAMMER       = 'TALIB_CDLINVERTEDHAMMER', //    Inverted Hammer
    TALIB_CDLKICKING       = 'TALIB_CDLKICKING', //           Kicking
    TALIB_CDLKICKINGBYLENGTH       = 'TALIB_CDLKICKINGBYLENGTH', //   Kicking - bull/bear determined by the longer marubozu
    TALIB_CDLLADDERBOTTOM       = 'TALIB_CDLLADDERBOTTOM', //      Ladder Bottom
    TALIB_CDLLONGLEGGEDDOJI       = 'TALIB_CDLLONGLEGGEDDOJI', //    Long Legged Doji
    TALIB_CDLLONGLINE       = 'TALIB_CDLLONGLINE', //          Long Line Candle
    TALIB_CDLMARUBOZU       = 'TALIB_CDLMARUBOZU', //          Marubozu
    TALIB_CDLMATCHINGLOW       = 'TALIB_CDLMATCHINGLOW', //       Matching Low
    TALIB_CDLMATHOLD       = 'TALIB_CDLMATHOLD', //           Mat Hold
    TALIB_CDLMORNINGDOJISTAR       = 'TALIB_CDLMORNINGDOJISTAR', //   Morning Doji Star
    TALIB_CDLMORNINGSTAR       = 'TALIB_CDLMORNINGSTAR', //       Morning Star
    TALIB_CDLONNECK       = 'TALIB_CDLONNECK', //            On-Neck Pattern
    TALIB_CDLPIERCING       = 'TALIB_CDLPIERCING', //          Piercing Pattern
    TALIB_CDLRICKSHAWMAN       = 'TALIB_CDLRICKSHAWMAN', //       Rickshaw Man
    TALIB_CDLRISEFALL3METHODS       = 'TALIB_CDLRISEFALL3METHODS', //  Rising/Falling Three Methods
    TALIB_CDLSEPARATINGLINES       = 'TALIB_CDLSEPARATINGLINES', //   Separating Lines
    TALIB_CDLSHOOTINGSTAR       = 'TALIB_CDLSHOOTINGSTAR', //      Shooting Star
    TALIB_CDLSHORTLINE       = 'TALIB_CDLSHORTLINE', //         Short Line Candle
    TALIB_CDLSPINNINGTOP       = 'TALIB_CDLSPINNINGTOP', // //       Spinning Top
    TALIB_CDLSTALLEDPATTERN       = 'TALIB_CDLSTALLEDPATTERN', // //    Stalled Pattern
    TALIB_CDLSTICKSANDWICH       = 'TALIB_CDLSTICKSANDWICH', // //     Stick Sandwich
    TALIB_CDLTAKURI       = 'TALIB_CDLTAKURI', // //            Takuri (Dragonfly Doji with very long lower shadow)
    TALIB_CDLTASUKIGAP       = 'TALIB_CDLTASUKIGAP', // //         Tasuki Gap
    TALIB_CDLTHRUSTING       = 'TALIB_CDLTHRUSTING', // //         Thrusting Pattern
    TALIB_CDLTRISTAR       = 'TALIB_CDLTRISTAR', // //           Tristar Pattern
    TALIB_CDLUNIQUE3RIVER       = 'TALIB_CDLUNIQUE3RIVER', // //      Unique 3 River
    TALIB_CDLUPSIDEGAP2CROWS       = 'TALIB_CDLUPSIDEGAP2CROWS', // //   Upside Gap Two Crows
    TALIB_CDLXSIDEGAP3METHODS       = 'TALIB_CDLXSIDEGAP3METHODS', // //  Upside/Downside Gap Three Methods

    M5_TALIB_CDL2CROWS                 = 'M5_TALIB_CDL2CROWS',    //    Two Crows
    M5_TALIB_CDL3BLACKCROWS       = 'M5_TALIB_CDL3BLACKCROWS', // Three Black Crows
    M5_TALIB_CDL3INSIDE       = 'M5_TALIB_CDL3INSIDE', //  Three Inside Up/Down
    M5_TALIB_CDL3LINESTRIKE       = 'M5_TALIB_CDL3LINESTRIKE', //   Three-Line Strike
    M5_TALIB_CDL3OUTSIDE       = 'M5_TALIB_CDL3OUTSIDE', //          Three Outside Up/Down
    M5_TALIB_CDL3STARSINSOUTH       = 'M5_TALIB_CDL3STARSINSOUTH', //     Three Stars In The South
    M5_TALIB_CDL3WHITESOLDIERS       = 'M5_TALIB_CDL3WHITESOLDIERS', //    Three Advancing White Soldiers
    M5_TALIB_CDLABANDONEDBABY       = 'M5_TALIB_CDLABANDONEDBABY', //     Abandoned Baby
    M5_TALIB_CDLADVANCEBLOCK       = 'M5_TALIB_CDLADVANCEBLOCK', //      Advance Block
    M5_TALIB_CDLBELTHOLD       = 'M5_TALIB_CDLBELTHOLD', //          Belt-hold
    M5_TALIB_CDLBREAKAWAY       = 'M5_TALIB_CDLBREAKAWAY', //         Breakaway
    M5_TALIB_CDLCLOSINGMARUBOZU       = 'M5_TALIB_CDLCLOSINGMARUBOZU', //   Closing Marubozu
    M5_TALIB_CDLCONCEALBABYSWALL       = 'M5_TALIB_CDLCONCEALBABYSWALL', //  Concealing Baby Swallow
    M5_TALIB_CDLCOUNTERATTACK       = 'M5_TALIB_CDLCOUNTERATTACK', //     Counterattack
    M5_TALIB_CDLDARKCLOUDCOVER       = 'M5_TALIB_CDLDARKCLOUDCOVER', //    Dark Cloud Cover
    M5_TALIB_CDLDOJI       = 'M5_TALIB_CDLDOJI', //              Doji
    M5_TALIB_CDLDOJISTAR       = 'M5_TALIB_CDLDOJISTAR', //          Doji Star
    M5_TALIB_CDLDRAGONFLYDOJI       = 'M5_TALIB_CDLDRAGONFLYDOJI', //     Dragonfly Doji
    M5_TALIB_CDLENGULFING       = 'M5_TALIB_CDLENGULFING', //         Engulfing Pattern
    M5_TALIB_CDLEVENINGDOJISTAR       = 'M5_TALIB_CDLEVENINGDOJISTAR', //   Evening Doji Star
    M5_TALIB_CDLEVENINGSTAR       = 'M5_TALIB_CDLEVENINGSTAR', //       Evening Star
    M5_TALIB_CDLGAPSIDESIDEWHITE       = 'M5_TALIB_CDLGAPSIDESIDEWHITE', //  Up/Down-gap side-by-side white lines
    M5_TALIB_CDLGRAVESTONEDOJI       = 'M5_TALIB_CDLGRAVESTONEDOJI', //    Gravestone Doji
    M5_TALIB_CDLHAMMER       = 'M5_TALIB_CDLHAMMER', //            Hammer
    M5_TALIB_CDLHANGINGMAN       = 'M5_TALIB_CDLHANGINGMAN', //        Hanging Man
    M5_TALIB_CDLHARAMI       = 'M5_TALIB_CDLHARAMI', //            Harami Pattern
    M5_TALIB_CDLHARAMICROSS       = 'M5_TALIB_CDLHARAMICROSS', //       Harami Cross Pattern
    M5_TALIB_CDLHIGHWAVE       = 'M5_TALIB_CDLHIGHWAVE', //          High-Wave Candle
    M5_TALIB_CDLHIKKAKE       = 'M5_TALIB_CDLHIKKAKE', //           Hikkake Pattern
    M5_TALIB_CDLHIKKAKEMOD       = 'M5_TALIB_CDLHIKKAKEMOD', //        Modified Hikkake Pattern
    M5_TALIB_CDLHOMINGPIGEON       = 'M5_TALIB_CDLHOMINGPIGEON', //      Homing Pigeon
    M5_TALIB_CDLIDENTICAL3CROWS       = 'M5_TALIB_CDLIDENTICAL3CROWS', //   Identical Three Crows
    M5_TALIB_CDLINNECK       = 'M5_TALIB_CDLINNECK', //            In-Neck Pattern
    M5_TALIB_CDLINVERTEDHAMMER       = 'M5_TALIB_CDLINVERTEDHAMMER', //    Inverted Hammer
    M5_TALIB_CDLKICKING       = 'M5_TALIB_CDLKICKING', //           Kicking
    M5_TALIB_CDLKICKINGBYLENGTH       = 'M5_TALIB_CDLKICKINGBYLENGTH', //   Kicking - bull/bear determined by the longer marubozu
    M5_TALIB_CDLLADDERBOTTOM       = 'M5_TALIB_CDLLADDERBOTTOM', //      Ladder Bottom
    M5_TALIB_CDLLONGLEGGEDDOJI       = 'M5_TALIB_CDLLONGLEGGEDDOJI', //    Long Legged Doji
    M5_TALIB_CDLLONGLINE       = 'M5_TALIB_CDLLONGLINE', //          Long Line Candle
    M5_TALIB_CDLMARUBOZU       = 'M5_TALIB_CDLMARUBOZU', //          Marubozu
    M5_TALIB_CDLMATCHINGLOW       = 'M5_TALIB_CDLMATCHINGLOW', //       Matching Low
    M5_TALIB_CDLMATHOLD       = 'M5_TALIB_CDLMATHOLD', //           Mat Hold
    M5_TALIB_CDLMORNINGDOJISTAR       = 'M5_TALIB_CDLMORNINGDOJISTAR', //   Morning Doji Star
    M5_TALIB_CDLMORNINGSTAR       = 'M5_TALIB_CDLMORNINGSTAR', //       Morning Star
    M5_TALIB_CDLONNECK       = 'M5_TALIB_CDLONNECK', //            On-Neck Pattern
    M5_TALIB_CDLPIERCING       = 'M5_TALIB_CDLPIERCING', //          Piercing Pattern
    M5_TALIB_CDLRICKSHAWMAN       = 'M5_TALIB_CDLRICKSHAWMAN', //       Rickshaw Man
    M5_TALIB_CDLRISEFALL3METHODS       = 'M5_TALIB_CDLRISEFALL3METHODS', //  Rising/Falling Three Methods
    M5_TALIB_CDLSEPARATINGLINES       = 'M5_TALIB_CDLSEPARATINGLINES', //   Separating Lines
    M5_TALIB_CDLSHOOTINGSTAR       = 'M5_TALIB_CDLSHOOTINGSTAR', //      Shooting Star
    M5_TALIB_CDLSHORTLINE       = 'M5_TALIB_CDLSHORTLINE', //         Short Line Candle
    M5_TALIB_CDLSPINNINGTOP       = 'M5_TALIB_CDLSPINNINGTOP', // //       Spinning Top
    M5_TALIB_CDLSTALLEDPATTERN       = 'M5_TALIB_CDLSTALLEDPATTERN', // //    Stalled Pattern
    M5_TALIB_CDLSTICKSANDWICH       = 'M5_TALIB_CDLSTICKSANDWICH', // //     Stick Sandwich
    M5_TALIB_CDLTAKURI       = 'M5_TALIB_CDLTAKURI', // //            Takuri (Dragonfly Doji with very long lower shadow)
    M5_TALIB_CDLTASUKIGAP       = 'M5_TALIB_CDLTASUKIGAP', // //         Tasuki Gap
    M5_TALIB_CDLTHRUSTING       = 'M5_TALIB_CDLTHRUSTING', // //         Thrusting Pattern
    M5_TALIB_CDLTRISTAR       = 'M5_TALIB_CDLTRISTAR', // //           Tristar Pattern
    M5_TALIB_CDLUNIQUE3RIVER       = 'M5_TALIB_CDLUNIQUE3RIVER', // //      Unique 3 River
    M5_TALIB_CDLUPSIDEGAP2CROWS       = 'M5_TALIB_CDLUPSIDEGAP2CROWS', // //   Upside Gap Two Crows
    M5_TALIB_CDLXSIDEGAP3METHODS       = 'M5_TALIB_CDLXSIDEGAP3METHODS', // //  Upside/Downside Gap Three Methods

    CDL_CURR_PRICE_1STCDL_BODY                          = 'CDL_Price1stCdlBod',
    CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM              = 'CDL_Price1stCdlBod2ndCdlStm',
    CDL_CURR_PRICE_INTRPTCDL_BODY                       = 'CDL_PriceIntrpCdlBod',
    CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM           = 'CDL_PriceIntrpCdlBod2ndCdlStm',
    CDL_CURR_PRICE_CDL_QUARTILE                         = 'CDL_PriceQuartile',
    CDL_CONSECUTIVE_COLORS                              = 'CDL_ConsecutiveColors',

    CDL_OPEN_RANGE_HIGH_LOW                             = 'CDL_OpenRangeHighLow',
    CDL_VWAP                                            = 'CDL_VWAP',
    CDL_SMA120                                          = 'CDL_SMA120',
    CDL_MIN_MAX_2H_VD_30M_160PCT                        = 'CDL_MinMax2hVd30m160pct',
    CDL_MIN_2H_VD_30M_160PCT                            = 'CDL_Min2hVd30m160pct',
    CDL_MAX_2H_VD_30M_160PCT                            = 'CDL_Max2hVd30m160pct',
    CDL_MIN_MAX_2H_VD_30M_220PCT                        = 'CDL_MinMax2hVd30m220pct',
    CDL_MIN_2H_VD_30M_220PCT                            = 'CDL_Min2hVd30m220pct',
    CDL_MAX_2H_VD_30M_220PCT                            = 'CDL_Max2hVd30m220pct',
    CDL_MIN_MAX_2H_VD_30M_400PCT                        = 'CDL_MinMax2hVd30m400pct',
    CDL_MIN_2H_VD_30M_400PCT                            = 'CDL_Min2hVd30m400pct',
    CDL_MAX_2H_VD_30M_400PCT                            = 'CDL_Max2hVd30m400pct',
    CDL_SUP_RES                                         = 'CDL_SupRes',
    CDL_OPEN_PRICE                                      = 'CDL_OpenPrice',
    CDL_PREV_DAY_CLOSE_PRICE                            = 'CDL_PrevDayClosePrice',

    M5_CDL_CURR_PRICE_1STCDL_BODY                          = 'M5_CDL_Price1stCdlBod',
    M5_CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM              = 'M5_CDL_Price1stCdlBod2ndCdlStm',
    M5_CDL_CURR_PRICE_INTRPTCDL_BODY                       = 'M5_CDL_PriceIntrpCdlBod',
    M5_CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM           = 'M5_CDL_PriceIntrpCdlBod2ndCdlStm',
    M5_CDL_CURR_PRICE_CDL_QUARTILE                         = 'M5_CDL_PriceQuartile',
}



const sm = new Map<StockSignalsEnum, string>([
    // ['', // 'None'],
    [StockSignalsEnum.SIGNALS_4H,                   'Signals 4hMinMax160pctStock3Pred'],
    [StockSignalsEnum.SIGNALS_LAST_3PRED, 'Signals Last3PredAvg'],
    [StockSignalsEnum.SIGNALS_LAST_15MIN, 'Signals Last15mAvg'],
    [StockSignalsEnum.SIGNALS_MACD_CROSS, 'Signals Macd Cross'],
    [StockSignalsEnum.SIGNALS_EMA6_PRICE, 'Signals Ema6 Price'],
    [StockSignalsEnum.SIGNALS_EMA12_PRICE, 'Signals Ema12 Price'],
    [StockSignalsEnum.SIGNALS_EMA26_PRICE, 'Signals Ema26 Price'],
    [StockSignalsEnum.SIGNALS_EMA26_PRICE_REPEAT,   'Signals Ema26 Price Repeat'],
    [StockSignalsEnum.SIGNALS_EMA120_PRICE, 'Signals Ema120 Price'],
    [StockSignalsEnum.SIGNALS_EMA6_CANDLE, 'Signals Ema6 Candle'],
    [StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY, 'Signals Ema6 CDL Day'],
    [StockSignalsEnum.SIGNALS_EMA12_CANDLE, 'Signals Ema12 Candle'],
    [StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY, 'Signals Ema12 CDL Day'],
    [StockSignalsEnum.SIGNALS_EMA26_CANDLE, 'Signals Ema26 Candle'],
    [StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY, 'Signals Ema26 CDL Day'],
    [StockSignalsEnum.SIGNALS_EMA120_CANDLE, 'Signals Ema120 Candle'],
    [StockSignalsEnum.SIGNALS_SMA120_PRICE, 'Signals Sma120 Price'],
    [StockSignalsEnum.SIGNALS_VWAP_PRICE, 'Signals VWAP Price'],
    [StockSignalsEnum.SIGNALS_CONSENSUS_3PRED_10M, 'Signals Cons. 3Pred10Min'],
    [StockSignalsEnum.SIGNALS_CONSENSUS_4PRED_10M, 'Signals Cons. 4Pred10Min'],
    [StockSignalsEnum.SIGNALS_CONSENSUS_5PRED_10M, 'Signals Cons. 5Pred10Min'],
    [StockSignalsEnum.PREDS_CONSENSUS, 'Preds Consensus'],

    [StockSignalsEnum.SIGNALS_2H, 'Signals 2hMinMax160pctStock3Pred'],
    [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_PRED, 'Signals 2hMinMax220pctPred'],
    [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK, 'Signals 2hMinMax220pctStock'],
    [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK, 'Signals 2hMinMax160pctStock'],
    [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE, 'Signals 2hMinMax220pctStockIns'],
    [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE, 'Signals 2hMinMax160pctStockIns'],
    [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED, 'Signals 2hMinMax220pctStock3Pred'],

    // 3predAvg below stock, stock below 2hvd30m (and reverse logic)
    [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED, 'Signals 2hVd30m160pctStock3Pred'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE, 'Signals 2hVd30m160pctStockIns'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK, 'Signals 2hVd30m160pctStock'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK, 'Signals 2hVd30m220pctStock'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE, 'Signals 2hVd30m220pctPred'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE, 'Signals 2hVd30m220pctStockIns'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED, 'Signals 2hVd30m220pctStock3Pred'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED, 'Signals 2hVd30m400pctStock3Pred'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE, 'Signals 2hVd30m400pctStockIns'],
    [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK, 'Signals 2hVd30m400pctStock'],


    [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK, 'Signals SupRes1hStock'],
    // [StockSignalsEnum.SIGNALS_TREND_LINES_STOCK_OUTSIDE, 'Signals TrendLineStockOut'],
    [StockSignalsEnum.SIGNALS_OPEN_LINE_STOCK, 'Signals OpenLineStock'],
    [StockSignalsEnum.SIGNALS_PREV_ClOSE_LINE_STOCK, 'Signals PrevCloseLineStock'],

    [StockSignalsEnum.SIGNALS_LAST_2H_AVG_STOCK, 'Signals Last2hAvgStock'],

    [StockSignalsEnum.SIGNALS_STOCK_GAP, 'Signals StockGap'],

    [StockSignalsEnum.SIGNALS_PRICE_PREV_DAY_HIGH_LOW, 'Signals Price Prev Day High/Low'],
    [StockSignalsEnum.SIGNALS_PRICE_OPEN_RANGE_HIGH_LOW, 'Signals Price Open Range High/Low'],
    [StockSignalsEnum.SIGNALS_PRICE_PREV_RANGE_HIGH_LOW, 'Signals Price Prev Range High/Low'],

    // [StockSignalsEnum.CDL_CONSECUTIVE_COLORS, 'CDL Consecutive Colors'],


]);

// Add missing using simple approach
Object.values(StockSignalsEnum).forEach((v)=>{
    if (!sm.has(v)) {
        sm.set(v, v.replace('_', ' '));
    }
});



const SignalsMap: ReadonlyMap<StockSignalsEnum, string> = new Map(sm);

export class StockSignalsHelper {
    private static taLibSgs = Array.from(StockSignalsHelper.signalsMap().keys()).filter(v=>StockSignalsHelper.info(v).isTaLib);

    // static isTaLibSignal(sg:StockSignalsEnum) {
    //
    //     return sg.startsWith('TALIB_') || sg.startsWith('M5_TALIB_');
    // }

    static info(sg:StockSignalsEnum) {
        const dt = sg.split('_');
        return {
            interval: dt.includes('M5') ? StockStatsIntervalEnum.MIN_5 : StockStatsIntervalEnum.MIN_1,
            cleanName: dt[dt.length-1],
            isTaLib: dt.includes('TALIB'),
            isCandle: dt.includes('CDL'),
        }
    }

    static taLibSignals() {
        return this.taLibSgs;
    }

    static signalsMap() {
        return SignalsMap;
    }
}
