import {
    Analysis,
    IChartPrediction,
    IPredictionAdmin,
    IQuoteFull,
    LinePoints,
    MinMaxLines,
    MinMaxPointsMap,
    MinMaxType, PointUpDown
} from "../interface";
import {MathEx} from "../utils";
import {StockSignalsEnum} from "../dict";

export interface LinePointsMinMax {
    factor: number,
    rangeSecs: number,
    min: LinePoints,
    max: LinePoints,
    stepSecs: number,
}

export interface LinePointsMinMaxAtr {
    min: LinePoints,
    max: LinePoints,
}
export interface MinMaxStatsAtr {
    stats2hVd30m100pct: LinePointsMinMaxAtr,
    stats2hVd30m160pct: LinePointsMinMaxAtr,
    stats2hVd30m220pct: LinePointsMinMaxAtr,
    stats2hVd30m400pct: LinePointsMinMaxAtr,
    signals: {
        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: PointUpDown[],

    }
}
export interface MinMaxStats {
    stats2hAvg: LinePointsMinMax,
    stats2h220pctAvg: LinePointsMinMax,
    stats2h160pctAvg: LinePointsMinMax,

    stats4h160pctAvg: LinePointsMinMax,
    stats4hAvg: LinePointsMinMax,

}
export class MinMaxData {

    public static roundToStep(timeSecs: number, stepSecs: number) {
        const step = stepSecs;
        return Math.ceil(timeSecs / step) * step;
    }

    public static emptyMap(): MinMaxPointsMap {
        return {points: new Map<number, MinMaxType>(), stepSecs: 1800}; // time-min/max map
    }

    /**
     * Points are rounded using "ceil": 1 sec becomes 1800 secs
     * Points are "forming" next 30m point
     * @param time
     * @param minMaxCumMap
     * @param val
     * @param deviationHalf
     */
    public static addValToMinMaxMap(
            time: number, minMaxCumMap: MinMaxPointsMap, val: number, deviationHalf: number) {
        const t180m = this.roundToStep(time, minMaxCumMap.stepSecs)
        if (!minMaxCumMap.points.has(t180m)) {
            minMaxCumMap.points.set(t180m, {
                minSum: 0,
                maxSum: 0,
                count: 0,
                items: [],
                time: t180m,
            });
        }
        const item = minMaxCumMap.points.get(t180m) as MinMaxType;
        const min = (val - deviationHalf);
        const max = (val + deviationHalf);
        item.minSum += min;
        item.maxSum += max;
        item.count++;
        item.items.push({min: min, max: max, time: time});
    }

    public static calcMinMax(minMaxCumMap: MinMaxPointsMap): MinMaxStats {

        const hour4 = 240*60;
        const hour2 = 120*60;

        const buildEmpty = (factor: number, rangeSecs: number): LinePointsMinMax=>{
            return {factor: 1, rangeSecs:hour4,stepSecs: minMaxCumMap.stepSecs,
                max: {x: [] as number[], y: [] as number[]},
                min:{x: [] as number[], y: [] as number[]}
            }
        }

        const minMaxStatsPred: MinMaxStats = {
            stats4hAvg: buildEmpty(0, hour4), // 100%
            stats4h160pctAvg: buildEmpty(0.6, hour4),

            stats2hAvg: buildEmpty(0, hour2),
            stats2h160pctAvg: buildEmpty(0.6, hour2),
            stats2h220pctAvg: buildEmpty(1.2, hour2),
        };

        type MinMaxAggrPred = {min: number, max: number, count:number};

        const addToMinMaxLine = (line: LinePointsMinMax, time:number, min: number, max:number) => {
            line.min.y.push(min);
            line.min.x.push(time);
            line.max.y.push(max);
            line.max.x.push(time);
        }

        const addNewPoint = (time:number, minMax2: MinMaxAggrPred, minMax4: MinMaxAggrPred) =>{
            const min4 = MathEx.round(minMax4.min/minMax4.count, 6);
            const max4 = MathEx.round(minMax4.max/minMax4.count, 6);

            const band4h60pct = (max4-min4)*0.6;

            addToMinMaxLine(minMaxStatsPred.stats4hAvg, time, min4, max4);
            addToMinMaxLine(minMaxStatsPred.stats4h160pctAvg, time, min4 - band4h60pct, max4 + band4h60pct);

            // 2h data
            const min2 = MathEx.round(minMax2.min/minMax2.count, 6);
            const max2 = MathEx.round(minMax2.max/minMax2.count, 6);
            const band2h160pct = (max2-min2)*0.6;
            const band2h220pct = (max2-min2)*1.2;
            addToMinMaxLine(minMaxStatsPred.stats2hAvg, time, min2, max2);
            addToMinMaxLine(minMaxStatsPred.stats2h160pctAvg, time, min2 - band2h160pct, max2 + band2h160pct);
            addToMinMaxLine(minMaxStatsPred.stats2h220pctAvg, time, min2-band2h220pct, max2+band2h220pct);
        }

        const keys = Array.from(minMaxCumMap.points.keys());
        for (let i=0;i<keys.length;i++) {
            const curr = minMaxCumMap.points.get(keys[i]) as MinMaxType;
            const currTime = curr.time;

            const addToMinMax = (minMaxAggr: MinMaxAggrPred, minMax: MinMaxType)=>{
                minMaxAggr.min+=minMax.minSum;
                minMaxAggr.max+=minMax.maxSum;
                minMaxAggr.count+=minMax.count;
                return minMaxAggr;
            }


            // create sum of previous points excluding current one
            const loopBehindUntil = currTime-hour4;
            const emptyMinMax = ():MinMaxAggrPred=>{return {min: 0, max:0, count: 0}};
            const minMax4: MinMaxAggrPred = emptyMinMax();
            const minMax2: MinMaxAggrPred = emptyMinMax();
            for (let y=i-1;y>=0;y--) {
                const prev = minMaxCumMap.points.get(keys[y]) as MinMaxType;
                const prevTime = prev.time;
                if (prevTime<=loopBehindUntil) {
                    break;
                }

                if (prevTime>currTime-hour4) {
                    addToMinMax(minMax4, prev);
                }

                if (prevTime>currTime-hour2) {
                    addToMinMax(minMax2, prev);
                }

            }

             // loop all points behind current one
            const prevCumData = {minSum: 0, maxSum: 0, count: 0} as MinMaxType;
            for (let z=0;z<curr.items.length;z++) {
                const p = curr.items[z];
                if (p.time<currTime) {
                    prevCumData.minSum+=p.min;
                    prevCumData.maxSum+=p.max;
                    prevCumData.count++;
                    const next = z+1<curr.items.length ? curr.items[z+1] : null;
                    if (!next || next.time!==p.time) {
                        // add only if next item isn't same time
                        // add point between 30m intervals using up to "currTime" data
                        const tmpMinMax2 = addToMinMax(Object.assign({}, minMax2), prevCumData);
                        const tmpMinMax4 = addToMinMax(Object.assign({}, minMax4), prevCumData);
                        addNewPoint(p.time, tmpMinMax2, tmpMinMax4);
                    }

                }
            }


            // add current point firstly
            addToMinMax(minMax4, curr);
            addToMinMax(minMax2, curr);

            // add intermediate points too


            addNewPoint(currTime, minMax2, minMax4);

        }
        return minMaxStatsPred;
    }

    public static calcMinMaxAtr(
            minMax: MinMaxStatsAtr, currPos: number, stats: readonly IQuoteFull[],
            last3predsAvg: LinePoints,
            predStats: readonly Analysis.Prediction[]
    ) {
        const posCurrQuote = stats[currPos];
        const stockClose = posCurrQuote.c;
        const curPosTime = posCurrQuote.t;
        const until = posCurrQuote.t-3600*2;
        let sumLower100 = 0;
        let sumUpper100 = 0;

        let sumLower160 = 0;
        let sumUpper160 = 0;

        let sumLower220 = 0;
        let sumUpper220 = 0;

        let sumLower400 = 0;
        let sumUpper400 = 0;

        let count = 0;
        for (let i=currPos;i>=0;i--) {
            const q = stats[i];
            if (q.t<until) {
                break;
            }
            const d30m = q.d30m as number;
            if (!d30m) {
                continue;
            }

            const close = q.c;
            sumUpper100 += (close + d30m);
            sumLower100 += (close - d30m);

            sumUpper160 += (close + 1.6*d30m);
            sumLower160 += (close - 1.6*d30m);

            sumUpper220 += (close + 2.2*d30m);
            sumLower220 += (close - 2.2*d30m);

            sumUpper400 += (close + 4*d30m);
            sumLower400 += (close - 4*d30m);

            count++;

        }
        if (!count) {
            return;
        }

        const addValue = (l: LinePointsMinMaxAtr, minSum: number, maxSum:number)=>{
            const min = MathEx.round(minSum/count, 4);
            l.min.x.push(curPosTime);
            l.min.y.push(min);

            const max = MathEx.round(maxSum/count, 4);
            l.max.x.push(curPosTime);
            l.max.y.push(max);
            return {min: min, max: max};
        }

        addValue(minMax.stats2hVd30m100pct, sumLower100, sumUpper100);
        const res160 = addValue(minMax.stats2hVd30m160pct, sumLower160, sumUpper160);
        const res220 = addValue(minMax.stats2hVd30m220pct, sumLower220, sumUpper220);
        const res400 = addValue(minMax.stats2hVd30m400pct, sumLower400, sumUpper400);

        const currMinMaxPos = minMax.stats2hVd30m100pct.min.x.length-1;
        const prevMinMaxPos = currMinMaxPos-1;

        const addUpDownIns = (sPoints:PointUpDown[], line: LinePointsMinMaxAtr)=>{
            if (prevMinMaxPos<0) {
                return;
            }
            const prevClose = stats[prevMinMaxPos].c;
            if (prevClose<line.min.y[prevMinMaxPos] && stockClose>=line.min.y[currMinMaxPos]) {
                sPoints.push({x: curPosTime, y: stockClose,isSellSignal: false,});
            }
            if (prevClose>=line.max.y[prevMinMaxPos] && stockClose<line.max.y[currMinMaxPos]) {
                sPoints.push({x: curPosTime, y: stockClose,isSellSignal: true,});
            }
        };
        addUpDownIns(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE], minMax.stats2hVd30m160pct);
        addUpDownIns(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE], minMax.stats2hVd30m220pct);
        addUpDownIns(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE], minMax.stats2hVd30m400pct);

        const addUpDownSimple = (sPoints:PointUpDown[], data:{min:number,max:number})=>{
            if (stockClose>data.max) {
                sPoints.push({x: curPosTime, y: stockClose,isSellSignal: true,});
            }
            if (stockClose<data.min) {
                sPoints.push({x: curPosTime, y: stockClose,isSellSignal: false,});
            }
        }

        addUpDownSimple(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK], res160);
        addUpDownSimple(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK], res220);
        addUpDownSimple(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK], res400);

        const addLast3Stock = (pY:number, signal: StockSignalsEnum, l: LinePointsMinMaxAtr)=>{
            // 220%
            const signals = (minMax.signals as any)[signal];
            if (pY>stockClose && stockClose>l.max.y[currMinMaxPos]) {
                signals.push({x: curPosTime,y: pY,isSellSignal: true,});
            }
            if (pY<stockClose && stockClose<minMax.stats2hVd30m220pct.min.y[currMinMaxPos]) {
                signals.push({x: curPosTime,y: pY,isSellSignal: false,});
            }
        }
        // find last3 point and compare below/above other lines
        // tslint:disable-next-line:prefer-for-of
        for (let y=0;y<last3predsAvg.x.length;y++) {
            const pTime = last3predsAvg.x[y];
            if (pTime>curPosTime) {
                break;
            }
            if (pTime===curPosTime) {
                const pY = last3predsAvg.y[y];
                // UP 160% signal
                addLast3Stock(pY, StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED, minMax.stats2hVd30m160pct);

                // 220%
                addLast3Stock(pY, StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED, minMax.stats2hVd30m220pct);
                addLast3Stock(pY, StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED, minMax.stats2hVd30m400pct);
            }
        }

        // tslint:disable-next-line:prefer-for-of
        for (let z=0;z<predStats.length;z++) {
            const pred = predStats[z];
            const predTime = pred.valueTime;
            const predY = pred.value;
            if (predTime > curPosTime) {
                break;
            }
            if (predTime===curPosTime) {
                if (predY>minMax.stats2hVd30m220pct.max.y[currMinMaxPos]) {
                    minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE].push({
                        x: curPosTime,
                        y: predY,
                        isSellSignal: true
                    });
                }
                if (predY<minMax.stats2hVd30m220pct.min.y[currMinMaxPos]) {
                    minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE].push({
                        x: curPosTime,
                        y: predY,
                        isSellSignal: false
                    });
                }
                break;
            }
        }

    }

    static emptyMinMaxAtr() {
        const buildEmpty = ()=>{return {
            max: {x: [] as number[], y: [] as number[]},
            min:{x: [] as number[], y: [] as number[]}
        }};
        const minMaxStatsAtr: MinMaxStatsAtr = {
            stats2hVd30m100pct: buildEmpty(),
            stats2hVd30m160pct: buildEmpty(),
            stats2hVd30m220pct: buildEmpty(),
            stats2hVd30m400pct: buildEmpty(),
            signals: {
                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED]: [],

                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK]: [],

                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE]: [],

                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: [],
            },
        }
        return minMaxStatsAtr;
    }

    // public static emptyLine() {
    //     return { x: [] as number[], y: [] as number[]} as LinePoints;
    // }
}
