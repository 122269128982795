import {StockDirectionType as DirectionType, IQuote, SignalPatternInput, SignalPrice} from "../../interface";
import {ValidationError} from "../../error";

export class StockPriceSignal {

    public static pricePrevDayHighLow(input:SignalPatternInput): null|SignalPrice {
        return StockPriceSignal.crossHighOrLow(input.currPos, input.stock, input.options.prevDayAverage as any);
    }

    public static priceOpenRangeHighLow(input:SignalPatternInput): null|SignalPrice {
        return StockPriceSignal.crossHighOrLow(input.currPos, input.stock, input.options.openRangeAverage as any);
    }

    public static pricePrevRangeHighLow(input:SignalPatternInput): null|SignalPrice {
        const range = input.options?.rangeCount;
        if (!range || range<=0) {
            throw new ValidationError('invalid range');
        }
        // calc min/max
        const stock = input.stock;
        const currPos = input.currPos;
        if (stock.length<11) {
            return null;
        }
        let min:number = null as any;
        let max:number = null as any;
        // exclude current minute from min/max calc
        for (let i=currPos-1;i>=currPos-range&&i>=0;i--) {
            const p = stock[i];
            if (min===null || p.l<min) {
                min = p.l;
            }
            if (max===null || p.h>max) {
                max = p.h;
            }
        }
        return StockPriceSignal.crossHighOrLow(currPos, stock, {high: max, low: min});
    }

    protected static crossHighOrLow(currPos: number, stock: Readonly<IQuote[]>, threshold: {high:number, low:number}) {
        if (threshold?.high==null || threshold?.low==null) {
            throw new ValidationError('invalid threshold data');
        }
        const curr = stock?.[currPos];
        const prev = stock?.[currPos-1];
        if (!curr || !prev) {
            return null;
        }
        if (prev.c<=threshold.high && curr.c>threshold.high) {
            return {
                dir: DirectionType.UP,
                y: threshold.high,
                price: curr.c,
            }
        }
        if (prev.c>=threshold.high && curr.c<threshold.high) {
            return {
                dir: DirectionType.DOWN,
                y: threshold.high,
                price: curr.c,
            }
        }

        if (prev.c>=threshold.low && curr.c<threshold.low) {
            return {
                dir: DirectionType.DOWN,
                y: threshold.low,
                price: curr.c,
            }
        }
        if (prev.c<=threshold.low && curr.c>threshold.low) {
            return {
                dir: DirectionType.UP,
                y: threshold.low,
                price: curr.c,
            }
        }

        return null;
    }


    public static stockSignalGap(input:SignalPatternInput) {
        if (input.options?.stockGapIntervalSecs && input.options?.stockGapRate) {
            const gapInt = input.options.stockGapIntervalSecs;
            const gapRate = input.options.stockGapRate;
            const sCurr = input.stock[input.currPos];

            for (let y=input.currPos-1;y>=0;y--) {
                const yP = input.stock[y];
                if (yP.t<sCurr.t-gapInt) {
                    break;
                }
                const expDiff = yP.c*gapRate;
                let dir:DirectionType = null as any;
                if (yP.c-sCurr.c>=expDiff) {
                    // BUY signal
                    dir = DirectionType.UP;
                }
                if (sCurr.c-yP.c>=expDiff) {
                    dir = DirectionType.DOWN;
                }
                if (null!==dir) {
                    return {price: sCurr.c, dir: dir} as SignalPrice;
                }
            }
        }
        return null;
    }

    static pricePrevCloseLine(input:SignalPatternInput): null|SignalPrice {
        let prevDayClosePrice = input.options?.prevDayClosePrice;
        const priceRate = input.options?.priceRate;
        const openPrice = input.options?.openPrice;
        if (null==prevDayClosePrice || null==priceRate || null==openPrice) {
            throw new ValidationError(`invalid prevDayClosePrice:${prevDayClosePrice} or priceRate:${priceRate} or openPrice:${openPrice}`)
        }
        // adjust prev close price
        prevDayClosePrice += (openPrice >= prevDayClosePrice ? 1 : -1) * prevDayClosePrice * priceRate;

        return StockPriceSignal.crossHighOrLow(input.currPos, input.stock, {high:prevDayClosePrice, low:prevDayClosePrice});
    }

    static priceOpenLine(input:SignalPatternInput): null|SignalPrice {
        const openPrice = input.options?.openPrice;
        if (null==openPrice) {
            throw new ValidationError('invalid openPrice')
        }

        return StockPriceSignal.crossHighOrLow(input.currPos, input.stock, {high:openPrice, low:openPrice});
    }

    // protected static calcSignal(cur:IQuote, prevP:IQuote,val:number) {
    //     if (cur.c>=val && prevP.c<val) {
    //         return {
    //             x: cur.t,
    //             y: cur.c,
    //             isSellSignal: false,
    //         } as PointUpDown;
    //     }
    //     if (cur.c<val && prevP.c>=val) {
    //         return {
    //             x: cur.t,
    //             y: cur.c,
    //             isSellSignal: true,
    //         } as PointUpDown;
    //     }
    //     return null;
    // }
}
