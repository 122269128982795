import { StockHelper, StockSignalsEnum, StockSignalsHelper, SignalSetup, FilterUserAverageScoreRange } from "predictagram-lib";
import { CumulativeChartLines } from "./enums";
import { ICumeDataSearchOptions, ILinesAndAnnotations } from "./interfaces";
import { StrategyProfitModel } from "models/strategy-profit.model";
import { CheckboxLineAnnotationType } from "./types";

export const DEFAULT_BUY_COLOR = '#5fbf3b';
export const DEFAULT_SELL_COLOR = '#df3434';

type SignalNameColorType = [name: string, buyColor: string, sellColor: string];

// export const colors = [
//   '#7873B066',
//   'rgba(112,112,118,0.4)',
//   'rgba(172,166,241,0.4)',
//   'rgba(83,70,97,0.4)',
//   'rgba(79,77,107,0.4)',
//   'rgba(155,151,197,0.4)',
//   'rgba(196,193,219,0.4)',
//   'rgba(219,219,240,0.4)',
// ];


export const defaultSymbol = 3;

export const toIntOrDefault = (v: any, def = null) => {
  return v !== '' ? parseInt(v, 10) : def;
}

export const defaultShowLines: CumulativeChartLines[] = [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.LAST_2H_AVG, CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.VWAP];

export const taLibSignals = StockSignalsHelper.taLibSignals();

export const leftBoxSignals: SignalNameColorType[] = [
  [StockSignalsEnum.SIGNALS_4H, '#10451D', '#c30010'],
  [StockSignalsEnum.SIGNALS_2H, '#18ac7f', '#ae4acd'],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED, '#75ff03', '#640606'],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK, '#03ff64', '#645906'],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK, '#cdff03', '#642a06'],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE, '#03ffb3', '#645906'],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE, '#ffd503', '#642a06'],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_PRED, '#75ffd3', '#642a76'],

  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED, '#18ac7f', '#ae4acd'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED, '#75ff03', '#640606'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED, '#b8a339', '#af531a'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK, '#cdff03', '#642a06'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK, '#03ff64', '#645906'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK, '#36b366', '#615813'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE, '#75ffd3', '#642a76'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE, '#03ffb3', '#645906'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE, '#40a889', '#feea67'],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE, '#ffd503', '#642a06'],
  [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK, '#9a946b', '#611a80'],
  [StockSignalsEnum.SIGNALS_LAST_2H_AVG_STOCK, '#9a946b', '#611a80'],
  [StockSignalsEnum.SIGNALS_PREV_ClOSE_LINE_STOCK, '#9a946b', '#611a80'],
  [StockSignalsEnum.SIGNALS_OPEN_LINE_STOCK, '#9a946b', '#611a80'],
];

export const signalsList: SignalNameColorType[] = [
  [StockSignalsEnum.SIGNALS_EMA6_PRICE, '#2e6a79', '#ca6868'],
  [StockSignalsEnum.SIGNALS_EMA12_PRICE, '#3d4e52', '#8d6262'],
  [StockSignalsEnum.SIGNALS_EMA26_PRICE, '#3ea7c1', '#f9dddd'],
  [StockSignalsEnum.SIGNALS_EMA26_PRICE_REPEAT, '#3ea7c1', '#f9dddd'],
  [StockSignalsEnum.SIGNALS_EMA120_PRICE, '#6ea0ac', '#9a6767'],

  [StockSignalsEnum.SIGNALS_EMA6_CANDLE, '#6ea0ac', '#9a6767'],
  [StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY, '#6ea0ac', '#9a6767'],
  [StockSignalsEnum.SIGNALS_EMA12_CANDLE, '#3ea7c1', '#f9dddd'],
  [StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY, '#3ea7c1', '#f9dddd'],
  [StockSignalsEnum.SIGNALS_EMA26_CANDLE, '#3d4e52', '#8d6262'],
  [StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY, '#3d4e52', '#8d6262'],
  [StockSignalsEnum.SIGNALS_EMA120_CANDLE, '#2e6a79', '#ca6868'],

  [StockSignalsEnum.SIGNALS_SMA120_PRICE, '#4a538c', '#b73d28'],

  [StockSignalsEnum.SIGNALS_LAST_3PRED, '#b8e9c5', '#edabb3'],
  [StockSignalsEnum.SIGNALS_LAST_15MIN, '#5ecaba', '#8c1a6a'],
  [StockSignalsEnum.SIGNALS_MACD_CROSS, '#73a197', '#f94449'],
  [StockSignalsEnum.SIGNALS_VWAP_PRICE, '#2738aa', '#ff2500'],
  [StockSignalsEnum.SIGNALS_CONSENSUS_3PRED_10M, '#6EDE8A', '#f69697'],
  [StockSignalsEnum.SIGNALS_CONSENSUS_4PRED_10M, '#b9de6e', '#f696ee'],
  [StockSignalsEnum.SIGNALS_CONSENSUS_5PRED_10M, '#ded16e', '#d996f6'],
  [StockSignalsEnum.PREDS_CONSENSUS, '#ded16e', '#d996f6'],
];


const lines: CumulativeChartLines[] = [
  CumulativeChartLines.PREDICTION_VALUES,
  CumulativeChartLines.LAST_5PRED_AVG,
  CumulativeChartLines.LAST_3PRED_AVG,
  CumulativeChartLines.LAST_15MIN_AVG,
  CumulativeChartLines.LAST_30MIN_AVG,
]

const extraLines: CumulativeChartLines[] = [
  CumulativeChartLines.SUP_RES_LINES,
  CumulativeChartLines.TREND_LINES,
  CumulativeChartLines.EXTRA_SUP_RES_LINES
]

export const lastNHour: CumulativeChartLines[] = [
  CumulativeChartLines.LAST_1H_AVG,
  CumulativeChartLines.LAST_2H_AVG,
  CumulativeChartLines.LAST_3H_AVG,
  CumulativeChartLines.LAST_4H_AVG,
  CumulativeChartLines.MIN_MAX_4H,
  CumulativeChartLines.MIN_MAX_2H,
  CumulativeChartLines.MIN_MAX_2H_ATR,
  CumulativeChartLines.MACD,
  CumulativeChartLines.EMA120,
  CumulativeChartLines.SMA120,
  CumulativeChartLines.VWAP,
]


export const StockSignalDefaultLines = new Map<StockSignalsEnum, CumulativeChartLines[] >([
  [StockSignalsEnum.SIGNALS_EMA6_PRICE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA12_PRICE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA26_PRICE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA120_PRICE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA6_CANDLE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA12_CANDLE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA26_CANDLE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],
  [StockSignalsEnum.SIGNALS_EMA120_CANDLE, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP,CumulativeChartLines.EMA120]],

  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_PRED, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H]],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H]],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H]],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H]],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H]],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H]],

  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]],

  [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.LAST_1H_AVG]],
  [StockSignalsEnum.SIGNALS_LAST_2H_AVG_STOCK, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.LAST_2H_AVG]],

  [StockSignalsEnum.CDL_VWAP, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.VWAP]],


  [StockSignalsEnum.CDL_SMA120, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.SMA120]],

]);
[
  StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_160PCT,
  StockSignalsEnum.CDL_MIN_2H_VD_30M_160PCT,
  StockSignalsEnum.CDL_MAX_2H_VD_30M_160PCT,

  StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_220PCT,
  StockSignalsEnum.CDL_MIN_2H_VD_30M_220PCT,
  StockSignalsEnum.CDL_MAX_2H_VD_30M_220PCT,

  StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_400PCT,
  StockSignalsEnum.CDL_MIN_2H_VD_30M_400PCT,
  StockSignalsEnum.CDL_MAX_2H_VD_30M_400PCT,
].forEach(s=>StockSignalDefaultLines.set(s, [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR]));

export const getSignalDefaultLines = (signals: StockSignalsEnum[]) => {
  const lines = signals.reduce((acc: CumulativeChartLines[], signal: StockSignalsEnum) => {
    const r = StockSignalDefaultLines.get(signal);
    if (r) {
      acc.push(...r)
    }
    return acc;
  }, []);
  return lines;
}


export const otherSetupSignals: SignalNameColorType[] = [

  [StockSignalsEnum.SIGNALS_PRICE_PREV_DAY_HIGH_LOW, '#abedc6', '#abedc6'],
  [StockSignalsEnum.SIGNALS_PRICE_OPEN_RANGE_HIGH_LOW, '#98D9C2', '#98D9C2'],
  [StockSignalsEnum.SIGNALS_PRICE_PREV_RANGE_HIGH_LOW, '#B9FFB7', '#B9FFB7'],

  [StockSignalsEnum.SIGNALS_STOCK_GAP, '#6b5670', '#6b5670'],
  [StockSignalsEnum.CDL_CONSECUTIVE_COLORS, '#74B572', '#74B572'],

  [StockSignalsEnum.CDL_CURR_PRICE_1STCDL_BODY, '#d4afb9', '#d4afb9'],
  [StockSignalsEnum.CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM, '#dce1e9', '#dce1e9'],
  [StockSignalsEnum.CDL_CURR_PRICE_INTRPTCDL_BODY, '#40798c', '#40798c'],
  [StockSignalsEnum.CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_CURR_PRICE_CDL_QUARTILE, '#f4cccc', '#f4cccc'],

  [StockSignalsEnum.CDL_OPEN_RANGE_HIGH_LOW, '#d4afb9', '#d4afb9'],
  [StockSignalsEnum.CDL_VWAP, '#dce1e9', '#dce1e9'],
  [StockSignalsEnum.CDL_SMA120, '#40798c', '#40798c'],

  [StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_160PCT, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_MIN_2H_VD_30M_160PCT, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_MAX_2H_VD_30M_160PCT, '#e6b451', '#e6b451'],

  [StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_220PCT, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_MIN_2H_VD_30M_220PCT, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_MAX_2H_VD_30M_220PCT, '#e6b451', '#e6b451'],

  [StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_400PCT, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_MIN_2H_VD_30M_400PCT, '#e6b451', '#e6b451'],
  [StockSignalsEnum.CDL_MAX_2H_VD_30M_400PCT, '#e6b451', '#e6b451'],

  [StockSignalsEnum.CDL_SUP_RES, '#f4cccc', '#f4cccc'],
  [StockSignalsEnum.CDL_OPEN_PRICE, '#98D9C2', '#98D9C2'],
  [StockSignalsEnum.CDL_PREV_DAY_CLOSE_PRICE, '#B9FFB7', '#B9FFB7'],

  [StockSignalsEnum.M5_CDL_CURR_PRICE_1STCDL_BODY, '#d4afb9', '#d4afb9'],
  [StockSignalsEnum.M5_CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM, '#dce1e9', '#dce1e9'],
  [StockSignalsEnum.M5_CDL_CURR_PRICE_INTRPTCDL_BODY, '#40798c', '#40798c'],
  [StockSignalsEnum.M5_CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM, '#e6b451', '#e6b451'],
  [StockSignalsEnum.M5_CDL_CURR_PRICE_CDL_QUARTILE, '#f4cccc', '#f4cccc'],

];


export const DEFAULT_TALIB_COLOR = '#2738aa';
export const taLibColors: SignalNameColorType[] = taLibSignals.map(s=>[s as string, DEFAULT_TALIB_COLOR, DEFAULT_TALIB_COLOR]);


const allSignalsColors = [
  ...otherSetupSignals,
  ...leftBoxSignals,
  ...signalsList,
  ...taLibColors,
];

export const stockSignalsColorMap: Map<StockSignalsEnum, [color1: string, color2: string]> = new Map<StockSignalsEnum, [color1: string, color2: string]>(
  allSignalsColors.map(([name, color1, color2]) => [name as StockSignalsEnum, [color1, color2]])
) ;


export enum ChartOptionsList {
  SHOW_AS_CANDLE            = 'showAsCandle',
  SHOW_5M_PRICE_CANDLE      = 'show5minPriceCandle',
  SHOW_LEGEND               = 'showLegend',
  SHOW_HOVER_INFO           = 'showHoverInfo',
}


export const linesAndAnnotationsOptions: ILinesAndAnnotations = {
  
  lines: lines.map(value => {
    return { [value]: false }
  }),

  extraLines: extraLines.map(value => {
    return { [value]: false }
  }),

  lastNHour: lastNHour.map(value => {
    return { [value]: false }
  }),

  chartOptions: [
    { [ChartOptionsList.SHOW_LEGEND] : true },
    { [ChartOptionsList.SHOW_AS_CANDLE]: true },
    { [ChartOptionsList.SHOW_5M_PRICE_CANDLE]: false},
    { [ChartOptionsList.SHOW_HOVER_INFO]: true},
  ] as {[key: string]:boolean}[],

  // leftBoxSignals: leftBoxSignals.map(signal => {
  //   const [name] = signal;
  //   return { [name]: false }
  // }),

  // signalsList: signalsList.map(signal => {
  //   const [name] = signal;
  //   return { [name]: false }
  // }),

  // taLibSignals: StockSignalsHelper.taLibSignals().map(signal => {
  //   return { [signal as string]: false }
  // }),

  // otherStockSignals: otherSetupSignals.map(signal => {
  //   const [name] = signal;
  //   return { [name]: false}
  // })

}

const prevDay = StockHelper.findTradingDay(new Date(), -1, true);
const wh = StockHelper.workingHours(new Date(prevDay.now() * 1000));

export const getSignalStockGap = (stockGapIntervalSecs: number, stockGapRate: number): SignalSetup => {
  const signalSetup = {
    signals: [
      { type: StockSignalsEnum.SIGNALS_STOCK_GAP, options: { stockGapIntervalSecs, stockGapRate } },
    ],
  } as SignalSetup;
  return signalSetup
}

export const defaultCumeSearchOptions: ICumeDataSearchOptions = {
  predictionTypes: StrategyProfitModel.defVals.predictionTypes,
  startTimePredMade: wh.start.getTimeSec(),
  endTimePredMade: wh.end.getTimeSec(),
  startHourEst: 9,
  endHourEst: 15,
  stockSymbolId: defaultSymbol,
  extraSymbolIds: [],
  openSignalSetup: getSignalStockGap(60 * 5, 0.001),
  userAverageScoreValue: "",
  userAverageScore: {} as FilterUserAverageScoreRange,
  userIds: "",
  predictionIds: [],
  predictionUserIds: [],
  predictionFirstMinStepSizeMoveMax: undefined
  // showSupResLinesChecked: false,
};

const annotationReducer = (acc: string[], annotation: CheckboxLineAnnotationType) => {
  const trueKeys = Object.entries(annotation)
    .filter(([key, value]) => value === true)
    .map(([key, _]) => key);
  return acc.concat(trueKeys);
}

const mapSignalToEnum = (annots: CheckboxLineAnnotationType[]) => {
  const getEnumKeyByValue = (enumObj: typeof StockSignalsEnum, value: string): keyof typeof StockSignalsEnum | null => {
    const entries = Object.entries(enumObj) as [keyof typeof StockSignalsEnum, string][];
    for (const [key, val] of entries) {
      if (val === value) {
        return key;
      }
    }
    return null; // return null if the value is not found
  }
  const mapKey = (s: string) => getEnumKeyByValue(StockSignalsEnum, s)
  const mapSignal = (signal: any) => StockSignalsEnum[signal as keyof typeof StockSignalsEnum];

  return annots.reduce(annotationReducer, []).map(mapKey).map(mapSignal);
}

const mapLinesToEnum = (annots: CheckboxLineAnnotationType[]) => {
  const getEnumKeyByValue = (enumObj: typeof CumulativeChartLines, value: string): keyof typeof CumulativeChartLines | null => {
    const entries = Object.entries(enumObj) as [keyof typeof CumulativeChartLines, string][];
    for (const [key, val] of entries) {
      if (val === value) {
        return key;
      }
    }
    return null; // return null if the value is not found
  }
  const mapKey = (s: string) => getEnumKeyByValue(CumulativeChartLines, s)
  const mapSignal = (signal: any) => CumulativeChartLines[signal as keyof typeof CumulativeChartLines];

  return annots.reduce(annotationReducer, []).map(mapKey).map(mapSignal);
}

export const getChartOptions = (annotations: ILinesAndAnnotations) => {
  return annotations.chartOptions.reduce(annotationReducer, [])
}

export const getShowLines = (annotations: ILinesAndAnnotations): CumulativeChartLines[] => {
  return [
    ...mapLinesToEnum(annotations.lines),
    ...mapLinesToEnum(annotations.lastNHour),
    ...mapLinesToEnum(annotations.extraLines),
  ];
}

const checkboxNames: Map<string, string> = new Map<string, string>([
    [CumulativeChartLines.LAST_1H_AVG, 'Last 1H Avg'],
    [CumulativeChartLines.LAST_2H_AVG,  'Last 2H Avg'],
    [CumulativeChartLines.LAST_3H_AVG, 'Last 3H Avg'],
    [CumulativeChartLines.LAST_4H_AVG, 'Last 4H Avg'],
    [CumulativeChartLines.MIN_MAX_4H, 'Min/Max 4H'],
    [CumulativeChartLines.MIN_MAX_2H, 'Min/Max 2H'],
    [CumulativeChartLines.MIN_MAX_2H_ATR, 'Min/Max 2H Vd30m'],
    [CumulativeChartLines.MACD, 'MACD'],
    [CumulativeChartLines.EMA120, 'EMA 120'],
    [CumulativeChartLines.SMA120, 'SMA 120'],
    [CumulativeChartLines.VWAP, 'VWAP'],

    [CumulativeChartLines.PREDICTION_VALUES, 'Prediction Values'],
    [CumulativeChartLines.LAST_5PRED_AVG, 'Last 5 Preds Avg'],
    [CumulativeChartLines.LAST_3PRED_AVG, 'Last 3 Preds Avg'],
    [CumulativeChartLines.LAST_15MIN_AVG, 'Last 15Min Avg'],
    [CumulativeChartLines.LAST_30MIN_AVG, 'Last 30Min Avg'],
  
    [CumulativeChartLines.SUP_RES_LINES, 'Show Sup/Res Lines'],
    [CumulativeChartLines.TREND_LINES, 'Show Trend Lines'],
    [CumulativeChartLines.EXTRA_SUP_RES_LINES, 'Show Extra Sup/Res'],

    [ChartOptionsList.SHOW_LEGEND, 'Show Legend'],
    [ChartOptionsList.SHOW_AS_CANDLE, 'Show As Candle'],
    [ChartOptionsList.SHOW_5M_PRICE_CANDLE, 'Show 5-Min Price Candle'],
    [ChartOptionsList.SHOW_HOVER_INFO, 'Show Hover Info'],

  ])

StockSignalsHelper.signalsMap().forEach((value, key) => {
  checkboxNames.set(key, value);
})

export {checkboxNames};
